import React, { useState } from 'react';
import { Copy, Check, Loader } from 'lucide-react';
import { toast } from 'react-toastify';
import ReactMarkdown from 'react-markdown';

interface ClinicalNotePopupProps {
  isOpen: boolean;
  onClose: () => void;
  note: string;
  isLoading?: boolean;
}

const ClinicalNotePopup: React.FC<ClinicalNotePopupProps> = ({ isOpen, onClose, note, isLoading = false }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyNote = async () => {
    if (note) {
      try {
        await navigator.clipboard.writeText(note);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
      } catch (err) {
        toast.error('Failed to copy note');
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-[56rem] max-h-[90vh] overflow-y-auto">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Clinical Note</h2>
            <div className="flex gap-2">
              <button
                onClick={handleCopyNote}
                className="text-gray-500 hover:text-gray-700 p-2 rounded-lg hover:bg-gray-100"
                title="Copy note"
              >
                {isCopied ? (
                  <Check className="w-5 h-5 text-green-500" />
                ) : (
                  <Copy className="w-5 h-5" />
                )}
              </button>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 p-2 rounded-lg hover:bg-gray-100"
              >
                ✕
              </button>
            </div>
          </div>
          <div className="prose prose-invert max-w-none bg-black text-white p-4 rounded">
            {isLoading ? (
              <div className="flex items-center justify-center py-8">
                <Loader className="animate-spin text-blue-500" size={32} />
              </div>
            ) : (
              <ReactMarkdown>
                {note}
              </ReactMarkdown>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicalNotePopup;
