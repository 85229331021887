import React, { useEffect, useState } from 'react';
import { Message, Encounter } from '../hooks/apis';
import EncounterUIDesktop from './EncounterUIDesktop';
import EncounterUIMobile from './EncounterUIMobile';
interface EncounterUIProps {
  encounter: Encounter | undefined;
  messages: Message[];
  isAnalysisLoading: boolean;
  onNewMessage: (message: string) => void;
  onDeleteMessage: (messageId: string) => void;
  onCreateClinicalNote: () => void;
  sendAudioData: (recordingId: string, segmentNumber: number, data: Blob, mimeType: string) => Promise<void>;
  sendAudioFinished: (recordingId: string, segmentCount: number) => Promise<void>;
  finishedTranscription: boolean;
  onShowNotePopup: () => void;
}

const EncounterUI: React.FC<EncounterUIProps> = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isMobile) {
    return <EncounterUIMobile {...props} />;
  }

  return <EncounterUIDesktop {...props} />;
};

export default EncounterUI; 