import React, { useState } from 'react';
import { Encounter } from '../hooks/apis';

interface ChooseNotesProps {
  onClose: () => void;
  onConfirm: (data: { selectedNotes: string[], additionalInstructions: string }) => void;
  encounter?: Encounter;
}

const ChooseNotes: React.FC<ChooseNotesProps> = ({ onClose, onConfirm, encounter }) => {
  const [additionalInstructions, setAdditionalInstructions] = useState('');
  const [selectedNotes, setSelectedNotes] = useState<string[]>([]);

  const noteTypes = [
    'Medical Note',
    'Work Note',
    'School Note',
    'Sports/Gym Note',
    'Patient Education Note'
  ];

  const handleCheckboxChange = (noteType: string) => {
    setSelectedNotes(prev => 
      prev.includes(noteType)
        ? prev.filter(type => type !== noteType)
        : [...prev, noteType]
    );
  };

  return (
    <div className="fixed inset-x-0 bottom-[72px] z-50 flex justify-end pr-4">
      <div className="bg-white dark:bg-tesla-dark-surface rounded-lg shadow-lg mb-2 border border-tesla-lightGray dark:border-tesla-dark-border min-w-[600px] max-w-[800px] w-full">
        <div className="flex max-h-[80vh]">
          <div className="w-64 border-r border-tesla-lightGray dark:border-tesla-dark-border p-4">
            <h3 className="font-semibold mb-3 text-tesla-black dark:text-tesla-dark-text">Note Types</h3>
            <div className="space-y-2">
              {noteTypes.map((noteType) => (
                <div key={noteType} className="flex items-center">
                  <input
                    type="checkbox"
                    id={noteType}
                    checked={selectedNotes.includes(noteType)}
                    onChange={() => handleCheckboxChange(noteType)}
                    className="h-4 w-4 text-tesla-blue rounded border-tesla-gray dark:border-tesla-dark-border focus:ring-tesla-blue dark:focus:ring-offset-tesla-dark-bg"
                  />
                  <label htmlFor={noteType} className="ml-2 text-sm text-gray-700 dark:text-tesla-dark-text">
                    {noteType}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="flex-1 p-4">
            <h2 className="text-xl font-bold mb-4 text-tesla-black dark:text-tesla-dark-text">Create Notes</h2>
            
            <div className="space-y-4">
              <div className="mb-6">
                <label 
                  htmlFor="instructions" 
                  className="block text-sm font-medium text-gray-700 dark:text-tesla-dark-text mb-2"
                >
                  Additional Instructions
                </label>
                <textarea
                  id="instructions"
                  rows={4}
                  className="w-full p-2 border border-tesla-gray dark:border-tesla-dark-border rounded-md 
                           focus:ring-tesla-blue focus:border-tesla-blue 
                           dark:bg-tesla-dark-bg dark:text-tesla-dark-text
                           dark:focus:ring-offset-tesla-dark-bg"
                  value={additionalInstructions}
                  onChange={(e) => setAdditionalInstructions(e.target.value)}
                  placeholder="Enter any additional instructions here..."
                />
              </div>

              <button
                onClick={() => {
                  onConfirm({ selectedNotes, additionalInstructions });
                  onClose();
                }}
                className="w-full p-3 bg-white dark:bg-tesla-dark-surface text-tesla-blue dark:text-blue-400 
                         rounded-md hover:bg-tesla-lightGray dark:hover:bg-tesla-dark-border/20 
                         transition-colors duration-200 border border-tesla-blue dark:border-blue-400"
              >
                Create Note
              </button>

              <button
                onClick={onClose}
                className="w-full p-3 bg-white dark:bg-tesla-dark-surface text-tesla-gray dark:text-tesla-dark-text/70 
                         rounded-md hover:bg-tesla-lightGray dark:hover:bg-tesla-dark-border/20 
                         transition-colors duration-200 border border-tesla-gray dark:border-tesla-dark-border"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 -z-10" 
        onClick={onClose}
      />
    </div>
  );
};

export default ChooseNotes; 