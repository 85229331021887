import React from 'react';
import { Check, X, Loader2 } from 'lucide-react';

interface InputWithValidationProps {
  name: string;
  label: string;
  type: string;
  autoComplete: string;
  validationLoading: boolean;
  error?: string | string[] | null;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showValidation: boolean;
}

const InputWithValidation: React.FC<InputWithValidationProps> = ({
  name,
  label,
  type,
  autoComplete,
  validationLoading,
  error,
  value,
  onChange,
  showValidation,
}) => {
  const hasError = error && error.length > 0;

  const renderErrorMessages = () => {
    if (!error) return null;

    if (typeof error === 'string') {
      return <p className="mt-2 text-sm text-red-600">{error}</p>;
    }

    return (
      <ul className="mt-2 text-sm text-red-600">
        {error.map((err, index) => (
          <li key={index} className="flex items-center">
            <X className="h-4 w-4 mr-1" /> {err}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 relative">
        <input
          id={name}
          name={name}
          type={type}
          autoComplete={autoComplete}
          value={value}
          onChange={onChange}
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm pr-10"
        />
        {showValidation && (
          validationLoading ? (
            <Loader2 className="absolute right-3 top-2 h-5 w-5 text-gray-400 animate-spin" />
          ) : hasError ? (
            <X className="absolute right-3 top-2 h-5 w-5 text-red-500" />
          ) : value ? (
            <Check className="absolute right-3 top-2 h-5 w-5 text-green-500" />
          ) : null
        )}
      </div>
      {showValidation && renderErrorMessages()}
    </div>
  );
};

export default InputWithValidation;
