import React, { useState } from 'react';
import { Encounter } from '../hooks/apis';
import SectionHeader from './SectionHeader';
import PlanSections from './PlanSections';

export interface PlanProps {
  encounter?: Encounter;
  isLoading: boolean;
}

const Plan: React.FC<PlanProps> = ({ encounter, isLoading }) => {
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});
  const [expandAll, setExpandAll] = useState<boolean | undefined>(undefined);

  const toggleExpandAll = () => {
    setExpandAll(prev => !prev);
  };

  const handleCheckboxChange = (id: string) => {
    setCheckedItems(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  return (
    <div className="h-full w-full flex flex-col">
      <SectionHeader
        title="Workup"
        isLoading={isLoading}
        expandAll={expandAll}
        onToggleExpandAll={toggleExpandAll}
        size="md"
      />

      {/* Scrollable Content */}
      <div className="flex-1 overflow-y-auto p-6 pt-2 bg-tesla-hover dark:bg-tesla-dark-bg">
        <PlanSections encounter={encounter} expandAll={expandAll} checkedItems={checkedItems} onCheckboxChange={handleCheckboxChange} />
      </div>
    </div>
  );
};

export default Plan;
