import React, { useRef, useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, ChevronDown, ChevronUp } from 'lucide-react';
import { Message } from '../hooks/apis';
import type { Encounter } from '../hooks/apis';
import EncounterSummary from './EncounterSummary';
import Analysis from './Analysis';
import Chat from './Chat';
import Plan from './Plan';
import TopBar from '../components/TopBar';

interface EncounterUIDesktopProps {
  encounter: Encounter | undefined;
  messages: Message[];
  isAnalysisLoading: boolean;
  onNewMessage: (message: string) => void;
  onDeleteMessage: (messageId: string) => void;
  onCreateClinicalNote: () => void;
  sendAudioData: (recordingId: string, segmentNumber: number, data: Blob, mimeType: string) => Promise<void>;
  sendAudioFinished: (recordingId: string, segmentCount: number) => Promise<void>;
  finishedTranscription: boolean;
  onShowNotePopup: () => void;
}

const EncounterUIDesktop: React.FC<EncounterUIDesktopProps> = ({
  encounter,
  messages,
  isAnalysisLoading,
  onNewMessage,
  onDeleteMessage,
  onCreateClinicalNote,
  sendAudioData,
  sendAudioFinished,
  finishedTranscription,
  onShowNotePopup
}) => {
  const [leftColumnVisible, setLeftColumnVisible] = useState(true);
  const [rightColumnVisible, setRightColumnVisible] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [leftWidth, setLeftWidth] = useState(33);
  const [rightWidth, setRightWidth] = useState(33);
  const [showChat, setShowChat] = useState(true);
  const [chatHeight, setChatHeight] = useState(70);
  const [isResizingChat, setIsResizingChat] = useState(false);

  const dragStartX = useRef(0);
  const draggedColumn = useRef<'left' | 'right' | null>(null);
  const initialWidths = useRef({ left: leftWidth, right: rightWidth });
  const containerRef = useRef<HTMLDivElement>(null);
  const chatResizeStart = useRef(0);
  const initialChatHeight = useRef(0);

  const handleMouseDown = (e: React.MouseEvent, column: 'left' | 'right') => {
    e.preventDefault();
    setIsDragging(true);
    dragStartX.current = e.clientX;
    draggedColumn.current = column;
    initialWidths.current = { left: leftWidth, right: rightWidth };
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging || !draggedColumn.current || !containerRef.current) return;

      const containerWidth = containerRef.current.offsetWidth;
      const deltaX = e.clientX - dragStartX.current;
      const deltaPercentage = (deltaX / containerWidth) * 100;

      if (draggedColumn.current === 'left') {
        const newLeftWidth = Math.min(Math.max(initialWidths.current.left + deltaPercentage, 20), 60);
        const middleWidth = 100 - newLeftWidth - rightWidth;
        if (middleWidth >= 20) {
          setLeftWidth(newLeftWidth);
        }
      } else {
        const newRightWidth = Math.min(Math.max(initialWidths.current.right - deltaPercentage, 20), 60);
        const middleWidth = 100 - leftWidth - newRightWidth;
        if (middleWidth >= 20) {
          setRightWidth(newRightWidth);
        }
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      draggedColumn.current = null;
    };

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, leftWidth, rightWidth]);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isResizingChat || !containerRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const mouseY = e.clientY - containerRect.top;
      const containerHeight = containerRect.height;

      const percentage = (mouseY / containerHeight) * 100;
      const newHeight = Math.min(Math.max(percentage, 0), 70);

      setChatHeight(newHeight);
    };

    const handleMouseUp = () => {
      setIsResizingChat(false);
    };

    if (isResizingChat) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizingChat]);

  const handleChatResizeStart = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsResizingChat(true);
    chatResizeStart.current = e.clientY;
    initialChatHeight.current = chatHeight;
  };

  return (
    <div className="flex flex-col h-screen bg-tesla-hover dark:bg-tesla-dark-background">
      <TopBar onShowNotePopup={onShowNotePopup} />
      <div id="columns-container" ref={containerRef} className="flex flex-1 h-[calc(100vh-64px)]">
        {/* Left Column */}
        <div
          className={`relative transition-all duration-300 ${leftColumnVisible ? '' : 'w-0 overflow-hidden'}`}
          style={{
            width: leftColumnVisible ? `${leftWidth}%` : '0',
            transition: isDragging ? 'none' : 'width 300ms'
          }}
        >
          <div className="h-full overflow-hidden">
            <EncounterSummary encounter={encounter} isLoading={isAnalysisLoading} />
          </div>
          <div
            className="absolute top-[88px] right-0 w-1 h-[calc(100%-88px)] bg-tesla-lightGray/30 dark:bg-tesla-dark-border/30 hover:bg-tesla-lightGray dark:hover:bg-tesla-dark-border cursor-col-resize z-10"
            onMouseDown={(e) => handleMouseDown(e, 'left')}
          />
        </div>

        {/* Toggle Left Column Button */}
        <button
          className="absolute left-0 top-[84px] z-20 p-1 pt-0 bg-hover dark:bg-tesla-dark-surface rounded-br shadow-md hover:bg-gray-50 dark:hover:bg-tesla-dark-border/20 text-tesla-black dark:text-tesla-dark-text"
          onClick={() => setLeftColumnVisible(!leftColumnVisible)}
          style={{
            left: leftColumnVisible ? `calc(${leftWidth}% - 20px)` : "0px",
            transition: isDragging ? 'none' : 'left 300ms'
          }}
        >
          {leftColumnVisible ? <ChevronLeft size={16} /> : <ChevronRight size={16} />}
        </button>

        {/* Middle Column */}
        <div
          className="flex flex-col relative h-full"
          style={{
            width: `${100 - (leftColumnVisible ? leftWidth : 0) - (rightColumnVisible ? rightWidth : 0)}%`,
            transition: isDragging ? 'none' : 'width 300ms'
          }}
        >
          {/* Plan Section */}
          <div
            className="flex-grow overflow-y-auto relative"
            style={{ height: `${showChat ? `${chatHeight}%` : 'calc(100% - 83px)'}` }}
          >
            <Plan encounter={encounter} isLoading={isAnalysisLoading} />
            <button
              className="absolute bottom-4 right-4 z-10 p-2 bg-hover dark:bg-tesla-dark-surface rounded-full shadow-md hover:bg-gray-50 dark:hover:bg-tesla-dark-border/20 text-[#02b6eb] dark:text-[#02b6eb]/70 border border-[#02b6eb] dark:border-[#02b6eb]/70"
              onClick={() => setShowChat(!showChat)}
            >
              {showChat ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
            </button>
          </div>

          {/* Chat Section */}
          <div
            className={`relative border-t border-tesla-lightGray dark:border-tesla-dark-border ${showChat ? '' : 'h-[144px]'}`}
            style={{ height: showChat ? `${100 - chatHeight}%` : undefined }}
          >
            <div
              className="absolute top-0 left-0 right-0 h-1 bg-tesla-lightGray/30 dark:bg-tesla-dark-border/30 hover:bg-tesla-lightGray dark:hover:bg-tesla-dark-border cursor-row-resize"
              onMouseDown={handleChatResizeStart}
            />
            <Chat
              messages={messages}
              onNewMessage={onNewMessage}
              onDeleteMessage={onDeleteMessage}
              sendAudioData={sendAudioData}
              sendAudioFinished={sendAudioFinished}
              finishedTranscription={finishedTranscription}
            />
          </div>
        </div>

        {/* Right Column */}
        <div
          className={`relative h-full transition-all duration-300 ${rightColumnVisible ? '' : 'w-0 overflow-hidden'}`}
          style={{
            width: rightColumnVisible ? `${rightWidth}%` : '0',
            transition: isDragging ? 'none' : 'width 300ms'
          }}
        >
          <div
            className="absolute top-[88px] left-0 w-1 h-[calc(100%-88px)] bg-tesla-lightGray/30 dark:bg-tesla-dark-border/30 hover:bg-tesla-lightGray dark:hover:bg-tesla-dark-border cursor-col-resize z-10"
            onMouseDown={(e) => handleMouseDown(e, 'right')}
          />
          <div className="h-full overflow-hidden">
            <Analysis
              encounter={encounter}
              isLoading={isAnalysisLoading}
              onShowNotePopup={onCreateClinicalNote}
            />
          </div>
        </div>

        {/* Toggle Right Column Button */}
        <button
          className="absolute right-0 top-[84px] z-20 p-1 pt-0 bg-hover dark:bg-tesla-dark-surface rounded-bl shadow-md hover:bg-gray-50 dark:hover:bg-tesla-dark-border/20 text-tesla-black dark:text-tesla-dark-text"
          onClick={() => setRightColumnVisible(!rightColumnVisible)}
          style={{
            right: rightColumnVisible ? `calc(${rightWidth}% - 20px)` : "0px",
            transition: isDragging ? 'none' : 'right 300ms'
          }}
        >
          {rightColumnVisible ? <ChevronRight size={16} /> : <ChevronLeft size={16} />}
        </button>

        {/* Drag overlay */}
        {isDragging && (
          <div className="fixed inset-0 z-50 cursor-col-resize" />
        )}
      </div>
    </div>
  );
};

export default EncounterUIDesktop; 