import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCurrentUser } from '../hooks/apis';
import { Loader } from 'lucide-react';

interface AuthOutletProps {
  fallbackPath: string;
}

export function AuthOutlet({ fallbackPath }: AuthOutletProps) {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { fetchCurrentUser, user } = useCurrentUser();

  useEffect(() => {
    const loadUser = async () => {
      await fetchCurrentUser();
      setIsLoading(false);
    };
    loadUser();
  }, [fetchCurrentUser]);

  if (isLoading) {
    return (
      <div className="h-screen w-screen flex items-center justify-center">
        <Loader className="animate-spin text-indigo-600" size={48} />
      </div>
    );
  }

  return user ? (
    <Outlet />
  ) : (
    <Navigate to={fallbackPath} state={{ from: location }} replace />
  );
}
