import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Mic, Loader } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

interface RecordingProps {
  sendAudioData: (recordingId: string, segmentNumber: number, data: Blob, mimeType: string) => Promise<void>;
  sendAudioFinished: (recordingId: string, segmentCount: number) => Promise<void>;
}

enum RecordingState {
  INITIALIZING = 'Initializing',
  RECORDING = 'Recording',
  SAVING = 'Saving Audio',
  TRANSCRIBING = 'Transcribing Audio'
}

const Recording: React.FC<RecordingProps> = ({ sendAudioData, sendAudioFinished }) => {
  const [recordingState, setRecordingState] = useState(RecordingState.INITIALIZING);
  const [duration, setDuration] = useState(0);
  const isRecordingRef = useRef<boolean>(false);
  const mediaRecorder = useRef<MediaRecorder | null>(null);

  const setError = (error: string) => {
    toast.error(error);
    console.error(error);
  };

  const handleStartRecording = async () => {
    try {
      if (isRecordingRef.current) {
        return;
      }
      isRecordingRef.current = true;
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mimeType = 'audio/mp4';
      mediaRecorder.current = new MediaRecorder(stream, { mimeType });
      const newRecordingId = uuidv4();
      console.log('started recording with id', newRecordingId);
      let segmentCount = 0;
      const audioSendPromises: Promise<void>[] = [];
      mediaRecorder.current.ondataavailable = async (event: BlobEvent) => {
        if (event.data.size > 0) {
          segmentCount += 1;
          console.log('sending audio segment', newRecordingId, segmentCount);
          const sendPromise = sendAudioData(newRecordingId, segmentCount, event.data, mimeType);
          audioSendPromises.push(sendPromise);
        };
      }
      mediaRecorder.current.onstop = async () => {
        console.log('stopping recording. waiting for audio segments to be sent');
        setRecordingState(RecordingState.TRANSCRIBING);
        await Promise.all(audioSendPromises);
        console.log('audio segments sent. sending end message');
        await sendAudioFinished(newRecordingId, segmentCount);
      };
      mediaRecorder.current.start(500);
      setRecordingState(RecordingState.RECORDING);
    } catch (error) {
      console.error('Error accessing the microphone:', error);
      setError('Error accessing the microphone: ' + error);
    }
  }

  const handleStopRecording = useCallback(async () => {
    setRecordingState(RecordingState.SAVING);
    if (mediaRecorder.current) {
      isRecordingRef.current = false;
      mediaRecorder.current.stop();
    }
  }, []);

  useEffect(() => {
    handleStartRecording();
    // we only want to run this once
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (recordingState === RecordingState.RECORDING) {
      interval = setInterval(() => {
        setDuration(prev => prev + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [recordingState]);

  useEffect(() => {
    console.log('recordingState', recordingState);
  }, [recordingState]);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const title = recordingState.toString();

  return (
    <div className="flex flex-grow items-center justify-between border border-red-200 rounded-md bg-red-50 p-3">
      <div className="flex items-center space-x-3">
        {recordingState === RecordingState.RECORDING ? (
          <>
            <div className="relative">
              <div className="absolute inset-0 bg-red-500 opacity-25 rounded-full animate-ping"></div>
              <Mic size={20} className="text-red-500 relative" />
            </div>
            <span className="text-red-500 font-medium">{formatTime(duration)}</span>
          </>
        ) : (
          <>
            <Loader className="animate-spin text-red-500" size={20} />
            <span className="text-red-500 font-medium">{title}</span>
          </>
        )}
      </div>
      {recordingState === RecordingState.RECORDING && (
        <button
          type="button"
          onClick={handleStopRecording}
          className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none"
        >
          Stop
        </button>
      )}
    </div>
  );
};

export default Recording;
