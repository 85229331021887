import React from 'react';
import TopBar from '../components/TopBar';

const HelpPage: React.FC = () => {
  return (
    <div className="flex flex-col h-screen-dynamic bg-tesla-white dark:bg-tesla-dark-bg">
      <TopBar onShowNotePopup={() => {}} />
      <div className="max-w-4xl mx-auto w-full p-6">
        <h1 className="text-3xl font-bold mb-8 text-gray-900 dark:text-tesla-dark-text">Centrum Auxilii</h1>

        {/* Quick Start Guide */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-tesla-dark-text">Guida Celeris Initii</h2>
          <div className="bg-white dark:bg-tesla-dark-surface rounded-lg shadow p-6 space-y-4">
            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0 w-8 h-8 bg-blue-100 dark:bg-blue-900/30 rounded-full flex items-center justify-center">
                <span className="text-blue-600 dark:text-blue-400 font-semibold">1</span>
              </div>
              <div>
                <h3 className="font-medium mb-2 text-gray-900 dark:text-tesla-dark-text">Constitue Tuum Profilem</h3>
                <p className="text-gray-600 dark:text-tesla-dark-text/70">Comple tuum profilem medicum cum tua specialitate et praeferentiis.</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0 w-8 h-8 bg-blue-100 dark:bg-blue-900/30 rounded-full flex items-center justify-center">
                <span className="text-blue-600 dark:text-blue-400 font-semibold">2</span>
              </div>
              <div>
                <h3 className="font-medium mb-2 text-gray-900 dark:text-tesla-dark-text">Crea Primam Notam</h3>
                <p className="text-gray-600 dark:text-tesla-dark-text/70">Preme "Nova Nota" et elige exemplar praeferitum.</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0 w-8 h-8 bg-blue-100 dark:bg-blue-900/30 rounded-full flex items-center justify-center">
                <span className="text-blue-600 dark:text-blue-400 font-semibold">3</span>
              </div>
              <div>
                <h3 className="font-medium mb-2 text-gray-900 dark:text-tesla-dark-text">Recognosce et Exporta</h3>
                <p className="text-gray-600 dark:text-tesla-dark-text/70">Recognosce notam generatam et exporta in formatum praeferitum.</p>
              </div>
            </div>
          </div>
        </section>

        {/* FAQs */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-tesla-dark-text">Quaestiones Frequentes</h2>
          <div className="bg-white dark:bg-tesla-dark-surface rounded-lg shadow divide-y divide-gray-200 dark:divide-tesla-dark-border">
            <div className="p-6">
              <h3 className="font-medium mb-2 text-gray-900 dark:text-tesla-dark-text">Quomodo mutare exemplar notarum?</h3>
              <p className="text-gray-600 dark:text-tesla-dark-text/70">Potes mutare exemplar notarum in configurationibus profili tui.</p>
            </div>
            <div className="p-6">
              <h3 className="font-medium mb-2 text-gray-900 dark:text-tesla-dark-text">Possumne personalizare stilum AI?</h3>
              <p className="text-gray-600 dark:text-tesla-dark-text/70">Ita, potes adjustare stilum scribendi AI in praeferentiis tuis.</p>
            </div>
            <div className="p-6">
              <h3 className="font-medium mb-2 text-gray-900 dark:text-tesla-dark-text">Quomodo exportare notas?</h3>
              <p className="text-gray-600 dark:text-tesla-dark-text/70">Post creationem notae, preme "Exporta" et elige formatum praeferitum.</p>
            </div>
            <div className="p-6">
              <h3 className="font-medium mb-2 text-gray-900 dark:text-tesla-dark-text">Suntne data mea secura?</h3>
              <p className="text-gray-600 dark:text-tesla-dark-text/70">Ita, utimur systemate securitatis industriae standardis. Nulla informatione patientium in nostris servis conservatur.</p>
            </div>
          </div>
        </section>

        {/* Contact Support */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-tesla-dark-text">Plus Auxilii Necessitas?</h2>
          <div className="bg-white dark:bg-tesla-dark-surface rounded-lg shadow p-6">
            <div className="grid md:grid-cols-2 gap-6">
              <div className="text-center p-4 border dark:border-tesla-dark-border rounded-lg">
                <h3 className="font-medium mb-2 text-gray-900 dark:text-tesla-dark-text">Auxilium Per Email</h3>
                <p className="text-gray-600 dark:text-tesla-dark-text/70 mb-4">Accipe responsum intra XXIV horas</p>
                <a 
                  href="mailto:auxilium@example.com"
                  className="inline-flex items-center text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300"
                >
                  auxilium@example.com
                </a>
              </div>
              <div className="text-center p-4 border dark:border-tesla-dark-border rounded-lg">
                <h3 className="font-medium mb-2 text-gray-900 dark:text-tesla-dark-text">Constitue Demonstrationem</h3>
                <p className="text-gray-600 dark:text-tesla-dark-text/70 mb-4">Accipe demonstrationem personalem</p>
                <button 
                  className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-tesla-dark-bg"
                >
                  Reserva Nunc
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* Resources */}
        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-tesla-dark-text">Subsidia Additionalia</h2>
          <div className="grid md:grid-cols-3 gap-6">
            <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" 
               className="block bg-white dark:bg-tesla-dark-surface rounded-lg shadow p-6 hover:shadow-lg dark:hover:bg-tesla-dark-border/20 transition-all">
              <h3 className="font-medium mb-2 text-gray-900 dark:text-tesla-dark-text">Tutoriales Video</h3>
              <p className="text-gray-600 dark:text-tesla-dark-text/70">Specta guides gradatim de usu systematis nostri</p>
            </a>
            <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" 
               className="block bg-white dark:bg-tesla-dark-surface rounded-lg shadow p-6 hover:shadow-lg dark:hover:bg-tesla-dark-border/20 transition-all">
              <h3 className="font-medium mb-2 text-gray-900 dark:text-tesla-dark-text">Documentatio</h3>
              <p className="text-gray-600 dark:text-tesla-dark-text/70">Guides detallati et documentatio API</p>
            </a>
            <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" 
               className="block bg-white dark:bg-tesla-dark-surface rounded-lg shadow p-6 hover:shadow-lg dark:hover:bg-tesla-dark-border/20 transition-all">
              <h3 className="font-medium mb-2 text-gray-900 dark:text-tesla-dark-text">Forum Communitatis</h3>
              <p className="text-gray-600 dark:text-tesla-dark-text/70">Connecte cum aliis utentibus et participa consilia</p>
            </a>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HelpPage; 