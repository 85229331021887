import _ from 'lodash';

/**
 * Merges a single NDJson row into an existing object.
 * 
 * @param data The existing object to merge into.
 * @param row The NDJson row to merge.
 * @returns A new object with the merged data.
 * 
 * @example
 * let encounterData = {};
 * encounterData = mergeNDJsonRow(encounterData, {"patient.name": "John Doe"});
 * encounterData = mergeNDJsonRow(encounterData, {"physicalExam.heent.head.inspection": "Normal"});
 * encounterData = mergeNDJsonRow(encounterData, {"differentialDiagnosis[0].disease": "Pneumonia"});
 * 
 * // The resulting encounterData object will be:
 * // {
 * //   patient: {
 * //     name: "John Doe"
 * //   },
 * //   physicalExam: {
 * //     heent: {
 * //       head: {
 * //         inspection: "Normal"
 * //       }
 * //     }
 * //   },
 * //   differentialDiagnosis: [
 * //     {
 * //       disease: "Pneumonia"
 * //     }
 * //   ]
 * // }
 */
export function mergeNDJsonRow(data: any, row: any): any {
  let newObject = _.cloneDeep(data)
  Object.keys(row).forEach(key => {
    const value = row[key];

    // Convert array notation from differentialDiagnosis[0] to differentialDiagnosis.0
    const lodashKey = key.replace(/\[(\d+)\]/g, '.$1');

    _.set(newObject, lodashKey, value);
  })
  return newObject
}
