import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Loader } from 'lucide-react';
import useAuthedWebSocket from '../hooks/useAuthedWebSocket';
import { useParams, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useEncounterMessages, Message, useEncounter, Encounter } from '../hooks/apis';
import { mergeNDJsonRow } from '../util/ndsjon';
import { EncounterData } from '../model/EncounterData';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClinicalNotePopup from '../components/ClinicalNotePopup';
import EncounterUI from '../components/EncounterUI';
import { arrayBufferToBase64 } from '../util/arrayBuffer';

const probabilitySort = (a: any, b: any) => {
  const probA = typeof a.probability === 'string' ? parseInt(a.probability) : a.probability;
  const probB = typeof b.probability === 'string' ? parseInt(b.probability) : b.probability;
  return probB - probA;
};


const createMergedEncounterData = (encounterData: EncounterData, row: any) => {
  if (row.hasOwnProperty('differentialDiagnosis[0]')) {
    encounterData.differentialDiagnosis = [];
    encounterData.updatedDifferentialDiagnosis = undefined;
  }
  const newData = mergeNDJsonRow(encounterData, row)
  if (newData?.differentialDiagnosis) {
    newData.differentialDiagnosis.sort(probabilitySort);
  }
  if (newData?.updatedDifferentialDiagnosis) {
    newData.updatedDifferentialDiagnosis.sort(probabilitySort);
  }
  return newData
}


const EncounterPage: React.FC = () => {
  const { encounterId } = useParams<{ encounterId: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const [messages, setMessages] = useState<Message[]>([]);
  const [finishedTranscription, setFinishedTranscription] = useState<boolean>(false);
  const initialMessageSent = useRef(false);
  const [encounter, setEncounter] = useState<Encounter | undefined>(undefined);
  const processingMessageIdRef = useRef<string | null>(null);
  const [clinicalNote, setClinicalNote] = useState('');
  const [isClinicalNoteLoading, setIsClinicalNoteLoading] = useState(false);
  const [showNotePopup, setShowNotePopup] = useState(false);
  const [isAnalysisLoading, setIsAnalysisLoading] = useState(false);

  const { fetchEncounter, error: encounterError, data: fetchedEncounter, isLoading: isLoadingEncounter } = useEncounter();
  const { fetchEncounterMessages, error: messagesError, data: fetchedMessages, isLoading: isLoadingMessages } = useEncounterMessages();
  const { sendJSONMessage, lastMessage } = useAuthedWebSocket(process.env.REACT_APP_WEBSOCKET_URL || '', {
    onOpen: () => console.log('WebSocket connection established.'),
    onClose: () => console.log('WebSocket connection closed.'),
    onError: (event) => console.error('WebSocket error:', event),
    shouldReconnect: () => true,
  });

  // Removed updateEncounterApi as it was never used
  // const { updateEncounterApi } = useUpdateEncounterApi();

  useEffect(() => {
    if (!location.state?.initialMessage && encounterId) {
      fetchEncounterMessages(encounterId);
      initialMessageSent.current = true;
    }

    // Handle initial message from Home page
    if (location.state?.initialMessage && !initialMessageSent.current) {
      handleNewUserMessage(location.state.initialMessage);
      initialMessageSent.current = true;
    }

    const state: any = {}
    // Handle start recording from Home page
    if (location.state?.startRecording) {
      state.startRecording = location.state?.startRecording;
    }
    // Clear the state after using it
    if (location.state) {
      navigate(location.pathname, { replace: true, state });
    }
    // we only want to run this once
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchedMessages) {
      setMessages(fetchedMessages);
    }
  }, [fetchedMessages]);

  const createClinicalNote = () => {
    setIsClinicalNoteLoading(true);
    setShowNotePopup(true);
    setClinicalNote('')
    sendJSONMessage({
      type: 'create_clinical_note',
      encounterId: encounterId,
      previousMessages: messages,
      encounterData: encounter?.data
    });
  }

  // const updateTitle = useCallback(async (content: any) => {
  //   console.log('UPDATETITLE called with content:', content);
    
  //   // Access the fields correctly - note the change in how we access patient.name
  //   const chiefComplaint = content?.['patient.chiefComplaintTwoWord'];
  //   const patientName = content?.['patient.name'];
  //   const assessment = content?.assessment;
    
  //   console.log('Extracted fields:', { chiefComplaint, patientName, assessment });

  //   if (encounter) {
  //     const description = assessment?.trim() || '';
      
  //     const updatedEncounter = {
  //       ...encounter,
  //       name: patientName,
  //       chiefComplaint: chiefComplaint || encounter.chiefComplaint,
  //       description: description || encounter.description,
  //     };
      
  //     console.log('Setting encounter to:', updatedEncounter);
      
  //     setEncounter(updatedEncounter as any);
      
  //     try {
  //       const updateData = {
  //         name: patientName,
  //         chiefComplaint: chiefComplaint || encounter.chiefComplaint,
  //         description: description || encounter.description,
  //       };
        
  //       console.log('Sending to API:', updateData);
  //       const result = await updateEncounterApi(encounter.id, updateData);
  //       console.log('API response:', result);
  //     } catch (error) {
  //       console.error('Error updating encounter:', error);
  //     }
  //   }
  // }, [encounter, updateEncounterApi]);

  // runs when a message is received from the websocket
  useEffect(() => {
    if (lastMessage !== null) {
      const message = JSON.parse(lastMessage.data);
      console.log('message received', message);
      if (message.type === 'stream_chat_response') {
        handleAIStreamResponse.current(message);
      } else if (message.type === 'encounter_stream_response') {
        if (processingMessageIdRef.current !== message.messageId) {
          console.log('ignoring message because it is not the current processing message', processingMessageIdRef.current, message);
          return;
        }

        setEncounter(prevEncounter => {
          const encounterData = createMergedEncounterData(prevEncounter?.data || {}, message.content)
          return {
            ...prevEncounter,
            data: encounterData
          } as any
        });
      } else if (message.type === 'encounter_stream_end') {
        if (processingMessageIdRef.current !== message.messageId) {
          console.log('ignoring message because it is not the current processing message', processingMessageIdRef.current, message);
          return;
        }
        setIsAnalysisLoading(false);
      } else if (message.type === 'clinical_note_stream') {
        setIsClinicalNoteLoading(false);
        setClinicalNote(prevNote => prevNote + message.content);
      } else if (message.type === 'audio_transcribed_empty') {
        setFinishedTranscription(true);
        toast.error('No speech detected. Please try again.');
      } else if (message.type === 'audio_transcribed') {
        setFinishedTranscription(true);
        processingMessageIdRef.current = message.messageId;
        // Add the audio message to the messages
        // but don't send it to the backend (it already has it)
        // and will stream the AI response automatically
        const newMessage: Message = {
          id: message.messageId || uuidv4(),  // Use messageId from backend or generate new
          role: 'user',
          type: 'user_input',
          data: { content: message.transcript }
        };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setIsAnalysisLoading(true);
      } else if (message.type === 'error') {
        setIsAnalysisLoading(false);
        toast.error(message.message);
      }
    }
  }, [lastMessage]); // Add updateTitle to dependencies

  function chunkString(str: string, maxSize: number) {
    if (!str) return [];
    if (str.length <= maxSize) return [str];

    const numChunks = Math.ceil(str.length / maxSize);
    const chunks = new Array(numChunks);

    for (let i = 0; i < numChunks; i++) {
      const start = i * maxSize;
      chunks[i] = str.substring(start, start + maxSize);
    }

    return chunks;
  }

  const sendAudioData = async (recordingId: string, segmentNumber: number, data: Blob, mimeType: string) => {
    const message: any = {
      type: 'audio_segment',
      timestamp: Date.now(),
      mimeType: mimeType,
      recordingId,
      segmentNumber,
      encounterId: encounterId
    };
    const audioBuffer = await data.arrayBuffer();
    const rawData = await arrayBufferToBase64(audioBuffer);
    // api gateway has a limit of 32k per request
    const chunkedRawData = chunkString(rawData, 30000);
    await Promise.all(chunkedRawData.map(async (chunk, index) => {
      const chunkMessage = { ...message, rawData: chunk, subSegmentNumber: index + 1 };
      console.log('sending audio data', chunk.length);
      await sendJSONMessage(chunkMessage);
    }));
  }

  const sendAudioFinished = async (recordingId: string, segmentCount: number) => {
    setFinishedTranscription(false);
    const endMessage = {
      type: 'audio_end',
      recordingId: recordingId,
      encounterId,
      totalSegments: segmentCount
    };
    await sendJSONMessage(endMessage);
  }

  const handleAIStreamResponse = useRef((responseMessage: any) => {
    if (processingMessageIdRef.current !== responseMessage.messageId) {
      console.log('ignoring message because it is not the current processing message', processingMessageIdRef.current, responseMessage);
      return;
    }
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      if (lastMessage && lastMessage.role === 'assistant') {
        // Append to the existing AI message
        const newLastMessage: Message = {
          ...lastMessage,
          data: {
            content: lastMessage.data.content + responseMessage.content
          }
        }
        return [...prevMessages.slice(0, -1), newLastMessage]
      } else {
        // Create a new AI message
        const newLastMessage: Message = {
          id: responseMessage.messageId || uuidv4(),  // Use messageId from backend or generate new
          type: "ai_response",
          role: 'assistant',
          data: {
            content: responseMessage.content
          }
        }
        return [...prevMessages, newLastMessage];
      }
    });
  })

  const handleNewUserMessage = (inputMessage: string, newEncounterId: string | null = null) => {
    if (inputMessage.trim()) {
      const messageId = uuidv4();
      processingMessageIdRef.current = messageId;
      const newMessage: Message = {
        id: messageId,
        role: 'user',
        type: 'user_input',
        data: { content: inputMessage }
      };
      // Only send the 40 most recent messages
      const previousMessages = [...messages.slice(-39)];
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setIsAnalysisLoading(true);
      sendJSONMessage({
        type: 'send_message',
        encounterId: newEncounterId || encounterId,
        message: newMessage,
        previousMessages: previousMessages,
        encounterData: encounter?.data
      });
    }
  };

  useEffect(() => {
    if (encounterId) {
      fetchEncounter(encounterId);
    }
  }, [encounterId, fetchEncounter]);

  useEffect(() => {
    console.log('fetchedEncounter', fetchedEncounter);
    console.log('encounterId', encounterId);
    if (fetchedEncounter) {
      setEncounter(fetchedEncounter);
    }
  }, [fetchedEncounter, encounterId]);

  useEffect(() => {
    document.title = encounter?.data?.patient?.chiefComplaintTwoWord || 'Encounter';
  }, [encounter]);
  

  const handleDeleteMessage = async (messageId: string) => {
    try {
      // Remove from local state first for immediate UI update
      setMessages(prevMessages => prevMessages.filter(msg => msg.id !== messageId));
      
      // Send delete request through WebSocket
      sendJSONMessage({
        type: 'delete_message',
        messageId: messageId,
        encounterId: encounter?.id
      });
    } catch (error) {
      console.error('Failed to delete message:', error);
      toast.error('Failed to delete message');
    }
  };

  if (!encounterId) {
    return <Navigate to="/home" replace />;
  }

  if (isLoadingEncounter || isLoadingMessages) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader className="animate-spin text-blue-500" size={48} />
      </div>
    );
  }

  if (messagesError || encounterError) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-red-500">Error loading data: {messagesError || encounterError}</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen-dynamic overflow-hidden bg-tesla-hover">
      <ToastContainer />
      <EncounterUI
        encounter={encounter}
        messages={messages}
        isAnalysisLoading={isAnalysisLoading}
        onNewMessage={handleNewUserMessage}
        onCreateClinicalNote={createClinicalNote}
        sendAudioData={sendAudioData}
        sendAudioFinished={sendAudioFinished}
        finishedTranscription={finishedTranscription}
        onDeleteMessage={handleDeleteMessage}
        onShowNotePopup={() => setShowNotePopup(true)}
      />
      <ClinicalNotePopup
        isOpen={showNotePopup}
        onClose={() => setShowNotePopup(false)}
        note={clinicalNote}
        isLoading={isClinicalNoteLoading}
      />
    </div>
  );
};

export default EncounterPage;
