import { useCallback } from 'react';
import useEchoWebSocket from './useEchoWebSocket';
import { Options } from 'react-use-websocket';

const useAuthedWebSocket = (url: string, options?: Options) => {
  const { sendMessage, lastMessage, readyState } = useEchoWebSocket(url, options);

  const sendJSONMessage = useCallback((message: Record<string, any>) => {
    const token = localStorage.getItem('token');
    const authedMessage = {
      ...message,
      token,
    };
    sendMessage(JSON.stringify(authedMessage));
  }, [sendMessage]);

  return { sendJSONMessage, lastMessage, readyState };
};

export default useAuthedWebSocket;
