import React, { useState } from 'react';
import { Encounter } from '../hooks/apis';
import SectionHeader from './SectionHeader';
import AnalysisSections from './AnalysisSections';
import ChooseNotes from './ChooseNotes';
import { FileText } from 'lucide-react';
import 'font-awesome/css/font-awesome.min.css';

export interface AnalysisProps {
  encounter?: Encounter;
  isLoading: boolean;
  onShowNotePopup: () => void;
}

const Analysis: React.FC<AnalysisProps> = ({ encounter, isLoading, onShowNotePopup }) => {
  const [expandAll, setExpandAll] = useState<boolean | undefined>(undefined);
  const [showChooseNotes, setShowChooseNotes] = useState(false);

  const toggleExpandAll = () => {
    setExpandAll(prev => !prev);
  };

  return (
    <div className="h-full w-full flex flex-col">
      <SectionHeader
        title="Analysis"
        isLoading={isLoading}
        expandAll={expandAll}
        onToggleExpandAll={toggleExpandAll}
        size="md"
      />

      {/* Scrollable Content */}
      <div className="flex-1 overflow-y-auto p-6 pt-2 bg-tesla-hover dark:bg-tesla-dark-bg">
        <AnalysisSections
          encounter={encounter}
          expandAll={expandAll}
        />
      </div>

      {/* Floating Create Note Button */}
      <div className="absolute bottom-0 right-0 p-4">
        <button
          type="button"
          className="w-12 h-12 p-0 bg-transparent border-2 border-tesla-green text-tesla-green rounded-md shadow-lg hover:bg-tesla-green hover:text-white focus:outline-none focus:ring-2 focus:ring-tesla-green focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200 flex items-center justify-center"
          disabled={isLoading}
          onClick={() => setShowChooseNotes(true)}
        >
          <FileText size={20} /> {/* Use the FileText icon */}
        </button>
      </div>

      {showChooseNotes && (
        <ChooseNotes
          onClose={() => setShowChooseNotes(false)}
          onConfirm={onShowNotePopup}
          encounter={encounter}
        />
      )}
    </div>
  );
};

export default Analysis;
