import React from 'react';

const NoteWriter: React.FC = () => {
  const [noteType, setNoteType] = React.useState('Medical Note');
  const [noteContent, setNoteContent] = React.useState('');

  const noteTypes = [
    'Medical Note',
    'Work Note',
    'School Note',
    'Sports/Gym Note',
    'Patient Education Note'
  ];

  const handleCopy = () => {
    navigator.clipboard.writeText(noteContent);
  };

  return (
    <div className="flex h-screen bg-gray-50 dark:bg-tesla-dark-bg">
      {/* Enhanced Sidebar */}
      <div className="w-72 bg-white dark:bg-tesla-dark-surface shadow-xl border-r border-gray-200 dark:border-tesla-dark-border">
        <div className="p-6">
          <h2 className="text-xl font-bold text-gray-800 dark:text-tesla-dark-text mb-6">Note Types</h2>
          <div className="space-y-1">
            {noteTypes.map((type) => (
              <button
                key={type}
                onClick={() => setNoteType(type)}
                className={`w-full text-left px-4 py-3 rounded-lg transition-all duration-200 flex items-center ${
                  noteType === type
                    ? 'bg-[#04b6ea] shadow-md'
                    : 'text-gray-600 dark:text-tesla-dark-text/70 hover:bg-gray-50 dark:hover:bg-tesla-dark-border/20 hover:text-gray-900 dark:hover:text-tesla-dark-text'
                }`}
              >
                <span className={`mr-3 ${noteType === type ? 'text-white' : ''}`}>
                  {type === 'Medical Note' && '🏥'}
                  {type === 'Work Note' && '💼'}
                  {type === 'School Note' && '🎓'}
                  {type === 'Sports/Gym Note' && '🏃'}
                  {type === 'Patient Education Note' && '📚'}
                </span>
                <span className={`font-medium ${noteType === type ? 'text-white' : ''}`}>
                  {type}
                </span>
              </button>
            ))}
          </div>
        </div>
        
        <div className="absolute bottom-0 w-72 p-6 border-t border-gray-200 dark:border-tesla-dark-border">
          <p className="text-sm text-gray-500 dark:text-tesla-dark-text/50">
            Select a note type to begin writing
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-6 overflow-auto">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-2xl font-bold mb-6 dark:text-tesla-dark-text">Clinical Notes</h1>
          
          {/* Patient Info Section */}
          <div className="bg-white dark:bg-tesla-dark-surface rounded-lg shadow p-4 mb-6">
            <h2 className="text-lg font-semibold mb-4 dark:text-tesla-dark-text">Patient Information</h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-tesla-dark-text">Patient Name</label>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-md border-gray-300 dark:border-tesla-dark-border dark:bg-tesla-dark-bg dark:text-tesla-dark-text shadow-sm focus:border-[#04b6ea] focus:ring-[#04b6ea] dark:focus:ring-offset-tesla-dark-bg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-tesla-dark-text">Date of Visit</label>
                <input
                  type="date"
                  className="mt-1 block w-full rounded-md border-gray-300 dark:border-tesla-dark-border dark:bg-tesla-dark-bg dark:text-tesla-dark-text shadow-sm focus:border-[#04b6ea] focus:ring-[#04b6ea] dark:focus:ring-offset-tesla-dark-bg"
                />
              </div>
            </div>
          </div>

          {/* Note Content Section */}
          <div className="bg-white dark:bg-tesla-dark-surface rounded-lg shadow p-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold dark:text-tesla-dark-text">{noteType}</h2>
            </div>
            
            <textarea
              value={noteContent}
              onChange={(e) => setNoteContent(e.target.value)}
              className="w-full h-64 rounded-md border-gray-300 dark:border-tesla-dark-border dark:bg-tesla-dark-bg dark:text-tesla-dark-text shadow-sm focus:border-[#04b6ea] focus:ring-[#04b6ea] dark:focus:ring-offset-tesla-dark-bg"
              placeholder={`Enter your ${noteType.toLowerCase()} here...`}
            />
            
            <div className="mt-4 flex justify-end space-x-4">
              <button
                onClick={handleCopy}
                className="px-4 py-2 bg-gray-200 dark:bg-tesla-dark-border text-gray-700 dark:text-tesla-dark-text rounded hover:bg-gray-300 dark:hover:bg-tesla-dark-border/70"
              >
                Copy Note
              </button>
              <button className="px-4 py-2 bg-gray-200 dark:bg-tesla-dark-border text-gray-700 dark:text-tesla-dark-text rounded hover:bg-gray-300 dark:hover:bg-tesla-dark-border/70">
                Save Draft
              </button>
              <button className="px-4 py-2 bg-[#04b6ea] text-white rounded hover:bg-[#03a3d3] dark:focus:ring-offset-tesla-dark-bg">
                Submit Note
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteWriter; 