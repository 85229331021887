import React, { useEffect, useState } from 'react';
import TopBar from '../components/TopBar';
import { useDarkMode } from '../contexts/DarkModeContext';
import { useAuth } from '../contexts/AuthContext'
import { api } from '../util/api';

type ThemeMode = 'system' | 'light' | 'dark';

interface UserPreferences {
  specialty: string;
  note_type: string;
  language: string;
  timezone: string;
  hospital_name: string;
  hospital_contact: string;
  system_prompt: string;
}

const SettingsPage: React.FC = () => {
  const { themePreference, setThemePreference } = useDarkMode();
  const { user, refreshUser } = useAuth();
  const [preferences, setPreferences] = useState<UserPreferences>({
    specialty: '',
    note_type: '',
    language: 'en',
    timezone: '',
    hospital_name: '',
    hospital_contact: '',
    system_prompt: '',
  });
  const [isSaving, setIsSaving] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user) {
      console.log('Setting user data:', user);
      setFirstName(user.first_name || user.firstName || '');
      setLastName(user.last_name || user.lastName || '');

      if (user.data?.preferences) {
        try {
          const userPrefs = typeof user.data.preferences === 'string' 
            ? JSON.parse(user.data.preferences) 
            : user.data.preferences;
          
          setPreferences({
            specialty: userPrefs.specialty || '',
            note_type: userPrefs.note_type || '',
            language: userPrefs.language || 'en',
            timezone: userPrefs.timezone || '',
            hospital_name: userPrefs.hospital_name || '',
            hospital_contact: userPrefs.hospital_contact || '',
            system_prompt: userPrefs.system_prompt || '',
          });
        } catch (e) {
          console.error('Error parsing preferences:', e);
        }
      }
      setIsLoading(false);
    }
  }, [user]);

  const handleSave = async () => {
    try {
      setIsSaving(true);
      console.log('Saving user data:', { firstName, lastName });
      
      const userResponse = await api.patch('/user', {
        first_name: firstName.trim(),
        last_name: lastName.trim()
      });

      if (!userResponse.ok) {
        const errorData = await userResponse.json();
        throw new Error(errorData.detail || 'Failed to save user data');
      }

      const userData = await userResponse.json();
      console.log('User update response:', userData);

      const preferencesResponse = await api.put('/user/preferences', preferences);
      if (!preferencesResponse.ok) {
        throw new Error('Failed to save preferences');
      }

      const prefsData = await preferencesResponse.json();
      console.log('Preferences update response:', prefsData);
      
      await refreshUser();
      console.log('User data after refresh:', user);
      
      alert('Settings saved successfully');
    } catch (error) {
      console.error('Failed to save settings:', error);
      alert(error instanceof Error ? error.message : 'Failed to save settings');
    } finally {
      setIsSaving(false);
    }
  };

  console.log('Current preferences state:', preferences);
  console.log('Current user data:', user);

  return (
    <div className="flex flex-col h-screen-dynamic bg-tesla-white dark:bg-tesla-dark-bg">
      <TopBar onShowNotePopup={() => {}} />
      <div className="max-w-3xl mx-auto w-full p-6">
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
          </div>
        ) : (
          <div className="max-w-xl space-y-6">
            {/* Name Fields */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-tesla-dark-text mb-2">
                  First Name
                </label>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="w-full p-2.5 border border-gray-300 dark:border-tesla-dark-border dark:bg-tesla-dark-bg dark:text-tesla-dark-text rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter first name"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-tesla-dark-text mb-2">
                  Last Name
                </label>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="w-full p-2.5 border border-gray-300 dark:border-tesla-dark-border dark:bg-tesla-dark-bg dark:text-tesla-dark-text rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter last name"
                />
              </div>
            </div>

            {/* Theme Selection */}
            <div className="flex items-center justify-between">
              <label className="block text-sm font-medium text-gray-700 dark:text-tesla-dark-text">
                Theme
              </label>
              <select
                value={themePreference}
                onChange={(e) => setThemePreference(e.target.value as ThemeMode)}
                className="px-3 py-1.5 border border-gray-300 dark:border-tesla-dark-border dark:bg-tesla-dark-bg dark:text-tesla-dark-text rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="system">Match System</option>
                <option value="light">Light</option>
                <option value="dark">Dark</option>
              </select>
            </div>

            {/* Specialty Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-tesla-dark-text mb-2">
                Medical Specialty
              </label>
              <select
                value={preferences.specialty}
                onChange={(e) => setPreferences(prev => ({
                  ...prev,
                  specialty: e.target.value,
                  note_type: ''
                }))}
                className="w-full p-2.5 border border-gray-300 dark:border-tesla-dark-border dark:bg-tesla-dark-bg dark:text-tesla-dark-text rounded-md"
              >
                {/* Specialties, match with prompts.py */}
                <option value="">Select specialty</option>
                <option value="allergy-immunology">Allergy & Immunology</option>
                <option value="anesthesiology">Anesthesiology</option>
                <option value="cardiology">Cardiology</option>
                <option value="dermatology">Dermatology</option>
                <option value="emergency-medicine">Emergency Medicine</option>
                <option value="endocrinology">Endocrinology</option>
                <option value="family-medicine">Family Medicine</option>
                <option value="gastroenterology">Gastroenterology</option>
                <option value="general-surgery">General Surgery</option>
                <option value="geriatrics">Geriatrics</option>
                <option value="hematology-oncology">Hematology & Oncology</option>
                <option value="infectious-disease">Infectious Disease</option>
                <option value="internal-medicine">Internal Medicine</option>
                <option value="nephrology">Nephrology</option>
                <option value="neurology">Neurology</option>
                <option value="neurosurgery">Neurosurgery</option>
                <option value="obstetrics-gynecology">Obstetrics & Gynecology</option>
                <option value="ophthalmology">Ophthalmology</option>
                <option value="orthopedics">Orthopedics</option>
                <option value="otolaryngology">Otolaryngology</option>
                <option value="pediatrics">Pediatrics</option>
                <option value="physical-medicine">Physical Medicine and Rehabilitation</option>
                <option value="physical-therapy">Physical Therapy</option>
                <option value="plastic-surgery">Plastic Surgery</option>
                <option value="preventive-medicine">Preventive Medicine</option>
                <option value="psychiatry">Psychiatry</option>
                <option value="pulmonology">Pulmonology</option>
                <option value="radiology">Radiology</option>
                <option value="rheumatology">Rheumatology</option>
                <option value="sports-medicine">Sports Medicine</option>
                <option value="thoracic-surgery">Thoracic Surgery</option>
                <option value="vascular-surgery">Vascular Surgery</option>
                <option value="veterinary">Veterinary</option>
                <option value="meeting-assistant">Meeting Assistant</option>
              </select>
            </div>

            {/* Hospital Name */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-tesla-dark-text mb-2">
                Hospital/Clinic Name
              </label>
              <input
                type="text"
                value={preferences.hospital_name}
                onChange={(e) => setPreferences(prev => ({
                  ...prev,
                  hospital_name: e.target.value
                }))}
                className="w-full p-2.5 border border-gray-300 dark:border-tesla-dark-border dark:bg-tesla-dark-bg dark:text-tesla-dark-text rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter hospital or clinic name"
              />
            </div>

            {/* Language Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-tesla-dark-text mb-2">
                Preferred Language
              </label>
              <select 
                value={preferences.language}
                onChange={(e) => setPreferences(prev => ({
                  ...prev,
                  language: e.target.value
                }))}
                className="w-full p-2.5 border border-gray-300 dark:border-tesla-dark-border dark:bg-tesla-dark-bg dark:text-tesla-dark-text rounded-md"
              >
                <option value="en">English</option>
                <option value="es">Spanish</option>
                <option value="fr">French</option>
                <option value="de">German</option>
                <option value="zh">Chinese</option>
                <option value="ja">Japanese</option>
                <option value="ko">Korean</option>
              </select>
            </div>

            {/* Hospital Contact */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-tesla-dark-text mb-2">
                Hospital/Clinic Contact
              </label>
              <input
                type="text"
                value={preferences.hospital_contact}
                onChange={(e) => setPreferences(prev => ({
                  ...prev,
                  hospital_contact: e.target.value
                }))}
                className="w-full p-2.5 border border-gray-300 dark:border-tesla-dark-border dark:bg-tesla-dark-bg dark:text-tesla-dark-text rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter hospital or clinic contact information"
              />
            </div>

            {/* Note Type */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-tesla-dark-text mb-2">
                Note Type
              </label>
              <select
                value={preferences.note_type}
                onChange={(e) => setPreferences(prev => ({
                  ...prev,
                  note_type: e.target.value
                }))}
                className="w-full p-2.5 border border-gray-300 dark:border-tesla-dark-border dark:bg-tesla-dark-bg dark:text-tesla-dark-text rounded-md"
              >
                <option value="">Select note type</option>
                <option value="ED-provider">ED Provider</option>
                <option value="soap">SOAP</option>
                <option value="progress">Progress Note</option>
                <option value="consult">Consultation</option>
                <option value="procedure">Procedure Note</option>
                <option value="discharge">Discharge Summary</option>
                <option value="admission">Admission Note</option>
                <option value="history-physical">History & Physical</option>
                <option value="veterinary">Veterinary</option>
                <option value="pediatric-clinic">Pediatric Clinic</option>
                <option value="meeting-assistant">Meeting Assistant</option>
              </select>
            </div>

            {/* System Prompt */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-tesla-dark-text mb-2">
                System Prompt
              </label>
              <textarea
                value={preferences.system_prompt}
                onChange={(e) => setPreferences(prev => ({
                  ...prev,
                  system_prompt: e.target.value
                }))}
                rows={4}
                className="w-full p-2.5 border border-gray-300 dark:border-tesla-dark-border dark:bg-tesla-dark-bg dark:text-tesla-dark-text rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter custom system prompt for AI interactions"
              />
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                Customize how the AI assistant interacts with you. Leave blank for default behavior.
              </p>
            </div>

            {/* Save Button */}
            <div className="pt-4">
              <button
                type="button"
                disabled={isSaving}
                onClick={handleSave}
                className="bg-blue-600 text-white px-6 py-2.5 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-tesla-dark-bg disabled:opacity-50"
              >
                {isSaving ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsPage; 