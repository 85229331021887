import { useEffect, useRef, useCallback } from 'react';
import useWebSocket, { ReadyState, Options } from 'react-use-websocket';

const ECHO_INTERVAL = 60000; // 60 seconds

const useEchoWebSocket = (url: string, options?: Options) => {
  const { sendMessage, lastMessage, readyState } = useWebSocket(url, options);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const sendEchoMessage = useCallback(() => {
    const message = {
      type: 'echo_message',
      message: 'Keeping connection alive',
      timestamp: Date.now(),
    };
    sendMessage(JSON.stringify(message));
  }, [sendMessage]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      intervalRef.current = setInterval(sendEchoMessage, ECHO_INTERVAL);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [readyState, sendEchoMessage]);

  return { sendMessage, lastMessage, readyState };
};

export default useEchoWebSocket;
