import { useState, useCallback } from 'react';

export interface ApiResponse<T> {
  data: T | null;
  error: string | null;
  isLoading: boolean;
}

interface ApiError {
  message: string;
  status: number;
  statusText: string;
  data: any;
}

export function useApi<T>() {
  const [state, setState] = useState<ApiResponse<T>>({
    data: null,
    error: null,
    isLoading: false,
  });

  const callApi = useCallback(async (path: string, options?: RequestInit): Promise<ApiResponse<T>> => {
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const baseUrl = apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl;
    const normalizedPath = path.startsWith('/') ? path.slice(1) : path;
    const url = `${baseUrl}/${normalizedPath}`;

    console.log('Calling API:', url);

    const defaultOptions: RequestInit = {
      credentials: 'omit',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        ...options?.headers,
      },
    };

    setState(prev => ({ ...prev, isLoading: true }));
    try {
      console.log('Fetching URL:', url);
      const response = await fetch(url, { ...defaultOptions, ...options });

      const contentType = response.headers.get('content-type');
      const responseData = contentType?.includes('application/json')
        ? await response.json()
        : await response.text();

      if (!response.ok) {
        const error: ApiError = {
          message: typeof responseData === 'object' ? responseData.message : responseData || 'API call failed',
          status: response.status,
          statusText: response.statusText,
          data: responseData
        };

        if (response.status === 0) {
          error.message = 'Unable to connect to the server. Please check your connection.';
        }

        console.error('API Error:', error);
        console.trace();
        const errorState = { data: null, error: error.message, isLoading: false };
        setState(errorState);
        return errorState;
      }

      const successState = { data: responseData, error: null, isLoading: false };
      setState(successState);
      return successState;
    } catch (error) {
      console.error('API Call Error:', error);
      console.trace();

      let errorMessage = 'An unexpected error occurred';
      if (error instanceof TypeError && error.message === 'Failed to fetch') {
        errorMessage = 'Unable to connect to the server. Please check your connection.';
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }

      const errorState = { data: null, error: errorMessage, isLoading: false };
      setState(errorState);
      return errorState;
    }
  }, []);

  return { ...state, callApi };
}

export function useAuthedApi<T>() {
  const { callApi, ...state } = useApi<T>();

  const callAuthedApi = useCallback(async (path: string, options?: RequestInit): Promise<ApiResponse<T>> => {
    const token = localStorage.getItem('token');
    if (!token) {
      return { data: null, error: 'No authentication token found', isLoading: false };
    }

    const authedOptions: RequestInit = {
      ...options,
      headers: {
        ...options?.headers,
        'Authorization': `Bearer ${token}`,
      },
    };

    return callApi(path, authedOptions);
  }, [callApi]);

  return { ...state, callApi: callAuthedApi };
}
