import React from 'react';
import { Encounter } from '../hooks/apis';
import CollapsibleSection from './CollapsibleSection';
import KeyValuePair from './KeyValuePair';
import DiagnosisCollapsible from './DiagnosisCollapsible';
import ReactMarkdown from 'react-markdown';

interface AnalysisSectionsProps {
  encounter?: Encounter;
  expandAll?: boolean;
}

const AnalysisSections: React.FC<AnalysisSectionsProps> = ({
  encounter,
  expandAll,
}) => {
  const encounterData = encounter?.data;

  // Create a compatible object for DiagnosisCollapsible
  const occamsRazorDiagnosis = encounterData?.occamsRazorDiagnosis
    ? {
        disease: encounterData.occamsRazorDiagnosis.disease,
        probability: encounterData.occamsRazorDiagnosis.probability, // Use the probability from the backend
        strongEvidence: encounterData.occamsRazorDiagnosis.strongEvidence || '', // Default to empty if not provided
        weakEvidence: encounterData.occamsRazorDiagnosis.weakEvidence || '', // Default to empty if not provided
        strongContradictoryFactors: encounterData.occamsRazorDiagnosis.strongContradictoryFactors || '', // Default to empty if not provided
        reasoning: encounterData.occamsRazorDiagnosis.reasoning,
      }
    : null;

  // Count the number of diagnoses with probability > 60%
  const highProbabilityCount = (encounterData?.updatedDifferentialDiagnosis || encounterData?.differentialDiagnosis || [])
    .filter(diagnosis => (diagnosis.probability ?? 0) > 50).length;

  return (
    <>
      {/* Conditionally display Occam's Razor Diagnosis */}
      {highProbabilityCount > 1 && occamsRazorDiagnosis && (
        <CollapsibleSection
          title="Unifying Diagnosis"
          expandAll={expandAll}
          emptyCheck={occamsRazorDiagnosis}
        >
          <div className="mt-2">
            <DiagnosisCollapsible
              diagnosis={occamsRazorDiagnosis}
              expandAll={expandAll}
            />
          </div>
        </CollapsibleSection>
      )}

      <CollapsibleSection
        title="Differential Diagnosis"
        defaultExpanded={true}
        expandAll={expandAll}
        emptyCheck={encounterData?.updatedDifferentialDiagnosis}
        className="mb-8 bg-tesla-hover"
      >
        <div className="mt-2">
          {(encounterData?.updatedDifferentialDiagnosis || encounterData?.differentialDiagnosis)?.map((diagnosis, index) => (
            diagnosis && (
              <DiagnosisCollapsible
                key={index}
                diagnosis={diagnosis}
                expandAll={expandAll}
              />
            )
          ))}
        </div>
      </CollapsibleSection>

      <CollapsibleSection
        title="Medical Decision Making"
        defaultExpanded={true}
        expandAll={expandAll}
        emptyCheck={{
          medicalDecisionMaking: encounterData?.medicalDecisionMaking,
          assessment: encounterData?.assessment,
          plan: encounterData?.plan,
          disposition: encounterData?.disposition
        }}
      >
        <div className="space-y-4">
          <div>
            <ReactMarkdown className="prose text-tesla-gray dark:text-tesla-dark-text/70 text-base">
              {encounterData?.medicalDecisionMaking || ''}
            </ReactMarkdown>
          </div>

          <div>
            <h4 className="font-semibold text-lg text-tesla-black dark:text-tesla-dark-text mb-1">Assessment</h4>
            <ReactMarkdown className="prose text-tesla-gray dark:text-tesla-dark-text/70 text-base">
              {encounterData?.assessment || ''}
            </ReactMarkdown>
          </div>

          <div>
            <h4 className="font-semibold text-lg text-tesla-black dark:text-tesla-dark-text mb-1">Plan</h4>
            <div className="text-tesla-gray dark:text-tesla-dark-text/70">
              {encounterData?.plan?.map((item, index) => (
                <ReactMarkdown 
                  key={index} 
                  className="prose dark:prose-invert text-base mb-2 text-tesla-gray dark:text-tesla-dark-text/70"
                >
                  {item}
                </ReactMarkdown>
              ))}
            </div>
          </div>

          <div>
            <h4 className="font-semibold text-lg text-tesla-black dark:text-tesla-dark-text mb-1">Disposition</h4>
            <div className="space-y-2">
              <KeyValuePair label="Decision" value={encounterData?.disposition?.decision} />
              <KeyValuePair label="Reasoning" value={encounterData?.disposition?.reasoning} />
            </div>
          </div>
        </div>
      </CollapsibleSection>

      <CollapsibleSection
        title="Academic Insights"
        expandAll={expandAll}
        emptyCheck={encounterData?.academicInsights}
      >
        <div className="text-tesla-gray dark:text-tesla-dark-text/70">
          {(() => {
            const insights = encounterData?.academicInsights;
            // Handle string type (old format)
            if (typeof insights === 'string') {
              return (
                <ReactMarkdown 
                  className="prose dark:prose-invert text-base mb-2 text-tesla-gray dark:text-tesla-dark-text/70"
                >
                  {insights}
                </ReactMarkdown>
              );
            }
            // Handle array type (new format)
            if (Array.isArray(insights)) {
              return insights.map((insight: string, index: number) => (
                <ReactMarkdown 
                  key={index} 
                  className="prose dark:prose-invert text-base mb-2 text-tesla-gray dark:text-tesla-dark-text/70"
                >
                  {insight}
                </ReactMarkdown>
              ));
            }
            // Handle undefined or null
            return null;
          })()}
        </div>
      </CollapsibleSection>
    </>
  );
};

export default AnalysisSections; 