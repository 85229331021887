import React, { useState } from 'react';
import { Encounter } from '../hooks/apis';
import SectionHeader from './SectionHeader';
import EncounterSummarySections from './EncounterSummarySections';

interface EncounterSummaryProps {
  encounter?: Encounter;
  isLoading: boolean;
}

const EncounterSummary: React.FC<EncounterSummaryProps> = ({ encounter, isLoading }) => {
  const [expandAll, setExpandAll] = useState<boolean | undefined>(undefined);

  const toggleExpandAll = () => {
    setExpandAll(prev => !prev);
  };

  return (
    <div className="h-full w-full flex flex-col">
      <SectionHeader
        title="Patient Data"
        isLoading={isLoading}
        expandAll={expandAll}
        onToggleExpandAll={toggleExpandAll}
        size="sm"
      />

      <div className="flex-1 overflow-y-auto p-6 pt-2 bg-tesla-hover dark:bg-tesla-dark-bg">
        <EncounterSummarySections
          encounter={encounter}
          expandAll={expandAll}
        />
      </div>
    </div>
  );
};

export default EncounterSummary;
