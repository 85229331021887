import { Encounter } from "../hooks/apis";

export const SAMPLE_ENCOUNTER_DATA: Encounter =
{
  "id": "test",
  "name": "test",
  "description": "test",
  "updatedAt": new Date().toISOString(),
  "data": {
    "updatedDifferentialDiagnosis": [
      {
        "disease": "Community-Acquired Pneumonia",
        "probability": 70,
        "strongEvidence": "Fever, productive cough, crackles on auscultation, elevated WBC count",
        "weakEvidence": "No recent travel or hospitalization",
        "strongContradictoryFactors": "Normal chest X-ray, no significant leukocytosis",
        "reasoning": "Patient's presentation with fever, cough, and crackles is consistent with pneumonia. The normal chest X-ray doesn't rule out early pneumonia. Absence of significant leukocytosis might indicate viral etiology or early bacterial infection.",

      },
      {
        "disease": "Pulmonary Embolism",
        "probability": 25,
        "strongEvidence": "Sudden onset dyspnea, tachycardia, risk factors (recent long flight)",
        "weakEvidence": "No chest pain, normal oxygen saturation",
        "strongContradictoryFactors": "No leg swelling or calf tenderness, fever present",
        "reasoning": "While the sudden onset of dyspnea and tachycardia raise concern for PE, the presence of fever and absence of typical PE symptoms like pleuritic chest pain make this less likely. D-dimer and further imaging needed to definitively rule out.",

      },
      {
        "disease": "Acute Exacerbation of Asthma",
        "probability": 20,
        "strongEvidence": "History of asthma, wheezing on exam",
        "weakEvidence": "No recent exposure to known triggers",
        "strongContradictoryFactors": "Fever present, productive cough",
        "reasoning": "Patient's history of asthma and current wheezing suggest possible exacerbation, but the presence of fever and productive cough are atypical and point towards an infectious process.",

      }
    ],
    "followUpQuestions": [
      {
        "questionTopic": "Recent travel history",
        "tipsToFocusOn": "Ask about any air travel or long car rides in the past month",
        "associatedDiagnoses": ["Pulmonary Embolism"],
        "power": "Recent travel history, especially long flights or car rides, is a significant risk factor for DVT/PE. A positive history would increase PE probability, while absence would decrease but not rule out PE."
      },
      {
        "questionTopic": "Characteristics of cough",
        "tipsToFocusOn": "Inquire about sputum color, consistency, and quantity",
        "associatedDiagnoses": ["Community-Acquired Pneumonia", "Acute Exacerbation of Asthma"],
        "power": "Purulent sputum production strongly suggests bacterial infection, supporting CAP diagnosis. Clear/frothy sputum more typical of asthma. Pattern helps differentiate between infectious and inflammatory processes."
      }
    ],
    "followUpPhysicalExam": [
      {
        "finding": "Chest percussion",
        "tipsToFocusOn": "Check for areas of dullness indicating consolidation",
        "associatedDiagnoses": ["Community-Acquired Pneumonia"],
        "power": "Dullness to percussion is highly specific for consolidation or pleural effusion. Finding would strongly support pneumonia diagnosis, though absence doesn't rule it out in early disease."
      },
      {
        "finding": "Lower extremity examination",
        "tipsToFocusOn": "Look for calf tenderness, swelling, or Homan's sign",
        "associatedDiagnoses": ["Pulmonary Embolism"],
        "power": "Unilateral leg swelling/tenderness suggests DVT, increasing PE probability. However, up to 50% of PE patients lack DVT signs, so normal exam doesn't rule out PE."
      }
    ],
    "patient": {
      "name": "John Doe",
      "age": 35,
      "ageUnit": "years",
      "sex": "Male",
      "chiefComplaint": "Shortness of breath and fever for 3 days",
      "chiefComplaintTwoWord": "Dyspnea, fever",
      "historyPresentIllness": "35-year-old male with a history of mild asthma presents with a 3-day history of progressively worsening shortness of breath, fever up to 101.5°F, and productive cough with yellowish sputum. He denies chest pain, leg swelling, or recent sick contacts. Patient reports a long international flight 2 weeks ago."
    },
    "reviewOfSystems": [
      {
        "system": "General",
        "normal": "",
        "abnormal": "Fever, fatigue, decreased appetite"
      },
      {
        "system": "Respiratory",
        "normal": "",
        "abnormal": "Shortness of breath, productive cough with yellow sputum, no hemoptysis"
      },
      {
        "system": "Cardiovascular",
        "normal": "",
        "abnormal": "No chest pain, no palpitations, no leg swelling"
      },
      {
        "system": "Gastrointestinal",
        "normal": "",
        "abnormal": "No nausea, vomiting, or diarrhea"
      }
    ],
    "medicalHistory": {
      "pmh": "Mild intermittent asthma, seasonal allergies",
      "psh": "Appendectomy (age 12)",
      "fh": "Father with hypertension, mother with type 2 diabetes",
      "sh": "Non-smoker, occasional alcohol use, works as a software engineer"
    },
    "medications": "Albuterol inhaler PRN, Loratadine 10mg daily",
    "allergies": "Penicillin (rash)",
    "vitals": {
      "sets": [
        {
          timestamp: "2024-03-14T10:30:00Z",
          temperature: {
            value: "38.5°C",
            abnormal: true
          },
          heartRate: {
            value: "102 bpm",
            abnormal: true
          },
          bloodPressure: {
            value: "128/82 mmHg",
            abnormal: false
          },
          respiratoryRate: {
            value: "22 breaths/min",
            abnormal: true
          },
          oxygenSaturation: {
            value: "95%",
            abnormal: false
          }
        }
      ]
    },
    "physicalExam": {
      "generalAssessment": {
        "generalAppearance": {
          normal: "",
          abnormal: "Mildly distressed, tachypneic"
        }
      },
      "heent": {
        "head": {
          normal: "Normocephalic, atraumatic. No visible lesions or deformities",
          abnormal: ""
        },
        "ears": {
          normal: "External ears normal, tympanic membranes intact bilaterally. Hearing grossly intact",
          abnormal: ""
        },
        "eyes": {
          normal: "Conjunctivae pink, sclera non-icteric. PERRLA, EOMI",
          abnormal: ""
        },
        "nose": {
          normal: "No nasal discharge or septal deviation. No sinus tenderness",
          abnormal: ""
        },
        "oralCavity": {
          normal: "Mucous membranes moist, no oral lesions. No tonsillar exudates",
          abnormal: ""
        },
        "throat": {
          normal: "",
          abnormal: "Mild pharyngeal erythema, no exudates. No lymphadenopathy"
        }
      },
      "neck": {
        normal: "No JVD or visible masses. No lymphadenopathy or thyromegaly. Full range of motion",
        abnormal: ""
      },
      "cardiovascular": {
        normal: "No chest wall deformities. Point of maximal impulse not displaced. Regular rhythm, no murmurs, rubs, or gallops",
        abnormal: "Tachycardic"
      },
      "respiratory": {
        normal: "No chest wall tenderness. Resonant throughout",
        abnormal: "Mild use of accessory muscles. Scattered crackles in right lower lobe, occasional wheezes bilaterally"
      },
      "abdomen": {
        normal: "No distension or visible masses. Normal bowel sounds in all quadrants. Tympanic throughout. Soft, non-tender, no organomegaly or masses",
        abnormal: ""
      },
      "musculoskeletal": {
        normal: "No joint deformities or erythema. No joint tenderness or effusions. Full range of motion in all joints",
        abnormal: ""
      },
      "neurological": {
        normal: "Alert and oriented x3. Cranial nerves II-XII grossly intact. 5/5 strength in all extremities. Intact to light touch and pinprick. Reflexes 2+ and symmetric throughout",
        abnormal: ""
      },
      "skin": {
        normal: "No rashes, lesions, or abnormal pigmentation. Skin warm and dry, no abnormal texture",
        abnormal: ""
      },
      "lymphatic": {
        normal: "No cervical, axillary, or inguinal lymphadenopathy",
        abnormal: ""
      },
      "urogenital": {
        "male": {
          normal: "Normal external genitalia, no hernias",
          abnormal: ""
        },
        "female": {
          normal: "",
          abnormal: "Deferred"
        }
      }
    },
    "ekgFindings": "Sinus tachycardia, rate 102 bpm, normal axis, no ST-T wave changes",
    "labResults": [
      {
        "name": "Complete Blood Count",
        "values": [
          {
            "name": "WBC",
            "value": "12.5 x10^9/L",
            "abnormal": true
          },
          {
            "name": "Hemoglobin",
            "value": "14.2 g/dL",
            "abnormal": false
          },
          {
            "name": "Platelets",
            "value": "220 x10^9/L",
            "abnormal": false
          }
        ]
      },
      {
        "name": "Basic Metabolic Panel",
        "values": [
          {
            "name": "Sodium",
            "value": "138 mEq/L",
            "abnormal": false
          },
          {
            "name": "Potassium",
            "value": "3.9 mEq/L",
            "abnormal": false
          },
          {
            "name": "Chloride",
            "value": "102 mEq/L",
            "abnormal": false
          },
          {
            "name": "CO2",
            "value": "24 mEq/L",
            "abnormal": false
          },
          {
            "name": "BUN",
            "value": "15 mg/dL",
            "abnormal": false
          },
          {
            "name": "Creatinine",
            "value": "0.9 mg/dL",
            "abnormal": false
          },
          {
            "name": "Glucose",
            "value": "110 mg/dL",
            "abnormal": false
          }
        ]
      },
      {
        "name": "C-Reactive Protein",
        "values": [
          {
            "name": "CRP",
            "value": "15 mg/L",
            "abnormal": true
          }
        ]
      }
    ],
    "imagingResults": [
      {
        "name": "Chest X-ray, PA and Lateral Views",
        "sections": [
          {
            "title": "Lungs/Pleura",
            "normal": "No pneumothorax. Clear upper lung fields.",
            "abnormal": [
              "Interstitial prominence in bilateral lower zones",
              "Small right pleural effusion"
            ]
          },
          {
            "title": "Heart/Mediastinum",
            "normal": "Normal mediastinal contours.",
            "abnormal": [
              "CTR 0.54",
              "Tortuous aortic arch with calcifications"
            ]
          },
          {
            "title": "Bones/Soft Tissues",
            "normal": "Right breast surgical clips. Otherwise unremarkable.",
            "abnormal": [
              "Degenerative thoracic spine changes"
            ]
          }
        ]
      }
    ],
    "clinicalDecisionToolsResults": [
      {
        "name": "CURB-65 Score",
        "values": [
          {
            "name": "Score",
            "value": "1 point (age < 65, no BUN > 19 mg/dL)",
            "abnormal": false
          },
          {
            "name": "Risk Assessment",
            "value": "Low risk, consider outpatient treatment",
            "abnormal": false
          }
        ]
      },
      {
        "name": "Wells' Criteria for Pulmonary Embolism",
        "values": [
          {
            "name": "Score",
            "value": "3 points (tachycardia, recent travel) - moderate risk",
            "abnormal": true
          },
          {
            "name": "Recommendation",
            "value": "D-dimer testing indicated",
            "abnormal": false
          }
        ]
      }
    ],
    "labTestsSuggested": [
      {
        "testName": "Blood cultures",
        "associatedDiagnoses": ["Community-Acquired Pneumonia"]
      },
      {
        "testName": "Sputum culture and gram stain",
        "associatedDiagnoses": ["Community-Acquired Pneumonia"]
      },
      {
        "testName": "D-dimer",
        "associatedDiagnoses": ["Pulmonary Embolism"]
      }
    ],
    "imagingStudiesSuggested": [
      {
        "study": "CT Pulmonary Angiogram",
        "associatedDiagnoses": ["Pulmonary Embolism"]
      },
      {
        "study": "Chest X-ray (follow-up in 6-8 weeks)",
        "associatedDiagnoses": ["Community-Acquired Pneumonia"]
      }
    ],
    "clinicalDecisionToolsSuggested": [
      {
        "tool": "Pneumonia Severity Index (PSI)",
        "associatedDiagnoses": ["Community-Acquired Pneumonia"]
      },
      {
        "tool": "PESI (Pulmonary Embolism Severity Index)",
        "associatedDiagnoses": ["Pulmonary Embolism"]
      }
    ],
    "criticalActions": [
      "Obtain blood cultures before initiating antibiotics",
      "Ensure adequate oxygenation and monitor oxygen saturation",
      "Initiate empiric antibiotic therapy for community-acquired pneumonia",
      "Reassess in 24-48 hours for clinical improvement"
    ],
    "medicalDecisionMaking": "The patient presents with symptoms and signs consistent with community-acquired pneumonia, including fever, productive cough, and focal crackles on examination. The chest X-ray showing a subtle infiltrate supports this diagnosis. While pulmonary embolism was initially considered due to recent travel and tachycardia, the CT pulmonary angiogram has ruled this out. The CURB-65 score suggests low risk, but given the patient's tachypnea and oxygen requirement, admission for intravenous antibiotics and monitoring is warranted.",
    "assessment": "35-year-old male with mild intermittent asthma presenting with community-acquired pneumonia, right lower lobe involvement. Low to moderate severity based on vital signs and CURB-65 score.",
    "plan": [
      "Admit to general medical floor for IV antibiotics and monitoring",
      "Start ceftriaxone 1g IV daily and azithromycin 500mg IV daily",
      "Oxygen therapy to maintain O2 saturation > 92%",
      "Encourage incentive spirometry and early ambulation",
      "Continue albuterol inhaler every 4-6 hours as needed for wheezing",
      "Monitor daily: vital signs, oxygen requirements, and clinical status",
      "Repeat chest X-ray in 24-48 hours if not improving",
      "Consider switch to oral antibiotics when clinically improving and afebrile for 24 hours",
      "Pneumococcal and influenza vaccination before discharge if not up to date",
      "Follow-up chest X-ray in 6-8 weeks to ensure resolution"
    ],
    "disposition": {
      "decision": "Admit to general medical floor",
      "reasoning": "Patient requires IV antibiotics, oxygen therapy, and close monitoring for potential clinical deterioration or development of complications. While CURB-65 score suggests possible outpatient management, the patient's tachypnea, oxygen requirement, and comorbid asthma warrant inpatient care."
    },
    "academicInsights": [
      "This case illustrates the importance of maintaining a broad differential diagnosis in patients presenting with dyspnea and fever. While community-acquired pneumonia was the leading diagnosis, the recent travel history necessitated consideration of pulmonary embolism. The use of clinical decision tools (CURB-65, Wells' criteria) aided in risk stratification and guided further testing. It's crucial to remember that chest X-rays may be normal in early pneumonia, and clinical judgment should guide management decisions. The case also highlights the value of the CT pulmonary angiogram in simultaneously evaluating for pulmonary embolism and confirming the presence of pneumonia."
    ]
  }
}

export const SAMPLE_ENCOUNTER_DATA2: Encounter =
{
  "id": "test2",
  "name": "test2",
  "description": "test2",
  "updatedAt": new Date().toISOString(),
  // "data": {
  //   "vitals": {
  //       "sets": [
  //           {
  //               "heartRate": {
  //                   "value": "76",
  //                   "abnormal": false
  //               },
  //               "timestamp": "",
  //               "temperature": {
  //                   "value": "36.2",
  //                   "abnormal": false
  //               },
  //               "bloodPressure": {
  //                   "value": "154/88",
  //                   "abnormal": true
  //               },
  //               "respiratoryRate": {
  //                   "value": "21",
  //                   "abnormal": true
  //               },
  //               "oxygenSaturation": {
  //                   "value": "84",
  //                   "abnormal": true
  //               }
  //           }
  //       ]
  //   },
  //   "patient": {
  //       "name": "Wonder Woman"
  //   },
  //   "allergies": "No known adverse drug reactions",
  //   "assessment": "72-year-old obese female with chronic hypoxemic respiratory failure, pulmonary hypertension, and episodic bulbar symptoms, likely due to a combination of Chronic Thromboembolic Pulmonary Hypertension (CTEPH) and Obstructive Sleep Apnea (OSA), with a possible concurrent neuromuscular disorder such as Myasthenia Gravis.",
  //   "initialDDX": "Pulmonary Hypertension, Congestive Heart Failure, Obstructive Sleep Apnea, Neuromuscular Disorder (e.g., Myasthenia Gravis or ALS), Chronic Thromboembolic Pulmonary Hypertension (CTEPH), Interstitial Lung Disease, Chronic Aspiration, Cerebrovascular Disease, Vocal Cord Dysfunction, Obesity Hypoventilation Syndrome",
  //   "labResults": [
  //       {
  //           "name": "Complete Blood Count",
  //           "values": [
  //               {
  //                   "name": "White Blood Cell Count",
  //                   "value": "normal",
  //                   "abnormal": false
  //               },
  //               {
  //                   "name": "Platelet Count",
  //                   "value": "normal",
  //                   "abnormal": false
  //               },
  //               {
  //                   "name": "Hemoglobin",
  //                   "value": "normal",
  //                   "abnormal": false
  //               }
  //           ]
  //       },
  //       {
  //           "name": "Basic Metabolic Panel",
  //           "values": [
  //               {
  //                   "name": "Sodium",
  //                   "value": "139",
  //                   "abnormal": false
  //               },
  //               {
  //                   "name": "Potassium",
  //                   "value": "4.7",
  //                   "abnormal": false
  //               },
  //               {
  //                   "name": "Chloride",
  //                   "value": "98",
  //                   "abnormal": false
  //               },
  //               {
  //                   "name": "Carbon Dioxide",
  //                   "value": "22",
  //                   "abnormal": false
  //               },
  //               {
  //                   "name": "Blood Urea Nitrogen",
  //                   "value": "16",
  //                   "abnormal": false
  //               },
  //               {
  //                   "name": "Creatinine",
  //                   "value": "0.86",
  //                   "abnormal": false
  //               },
  //               {
  //                   "name": "Glucose",
  //                   "value": "120",
  //                   "abnormal": false
  //               }
  //           ]
  //       },
  //       {
  //           "name": "Liver Function Tests",
  //           "values": [
  //               {
  //                   "name": "AST",
  //                   "value": "normal",
  //                   "abnormal": false
  //               },
  //               {
  //                   "name": "ALT",
  //                   "value": "normal",
  //                   "abnormal": false
  //               },
  //               {
  //                   "name": "Bilirubin",
  //                   "value": "normal",
  //                   "abnormal": false
  //               },
  //               {
  //                   "name": "Albumin",
  //                   "value": "normal",
  //                   "abnormal": false
  //               },
  //               {
  //                   "name": "Globulin",
  //                   "value": "normal",
  //                   "abnormal": false
  //               }
  //           ]
  //       },
  //       {
  //           "name": "Other Tests",
  //           "values": [
  //               {
  //                   "name": "Calcium",
  //                   "value": "normal",
  //                   "abnormal": false
  //               },
  //               {
  //                   "name": "Magnesium",
  //                   "value": "normal",
  //                   "abnormal": false
  //               },
  //               {
  //                   "name": "Phosphate",
  //                   "value": "normal",
  //                   "abnormal": false
  //               },
  //               {
  //                   "name": "Creatine Kinase",
  //                   "value": "normal",
  //                   "abnormal": false
  //               },
  //               {
  //                   "name": "Lactate",
  //                   "value": "normal",
  //                   "abnormal": false
  //               },
  //               {
  //                   "name": "Lipase",
  //                   "value": "normal",
  //                   "abnormal": false
  //               }
  //           ]
  //       },
  //       {
  //           "name": "Urinalysis",
  //           "values": [
  //               {
  //                   "name": "Protein",
  //                   "value": "1+",
  //                   "abnormal": true
  //               }
  //           ]
  //       },
  //       {
  //           "name": "Group A Streptococcal Antigen",
  //           "values": [
  //               {
  //                   "name": "Result",
  //                   "value": "negative",
  //                   "abnormal": false
  //               }
  //           ]
  //       },
  //       {
  //           "name": "Throat Culture",
  //           "values": [
  //               {
  //                   "name": "Result",
  //                   "value": "no growth",
  //                   "abnormal": false
  //               }
  //           ]
  //       }
  //   ],
  //   "disposition": {
  //       "decision": "Admit to step-down unit or ICU",
  //       "reasoning": "Patient requires close monitoring due to respiratory failure, pulmonary hypertension, and risk of neurological deterioration. Complex workup and management necessitate inpatient care."
  //   },
  //   "ekgFindings": "Atrial flutter with ventricular rate of 75 beats per minute, rightward axis, and low precordial QRS voltage",
  //   "medications": "Furosemide, apixaban, aspirin, amlodipine, lisinopril, metoprolol, lovastatin, metformin, sertraline",
  //   "physicalExam": {
  //       "neck": {
  //           "normal": "",
  //           "abnormal": ""
  //       },
  //       "skin": {
  //           "normal": "",
  //           "abnormal": "Venous stasis dermatitis"
  //       },
  //       "heent": {
  //           "ears": {
  //               "normal": "",
  //               "abnormal": ""
  //           },
  //           "eyes": {
  //               "normal": "",
  //               "abnormal": ""
  //           },
  //           "head": {
  //               "normal": "",
  //               "abnormal": ""
  //           },
  //           "nose": {
  //               "normal": "",
  //               "abnormal": ""
  //           },
  //           "throat": {
  //               "normal": "No stridor",
  //               "abnormal": ""
  //           },
  //           "oralCavity": {
  //               "normal": "Clear oropharynx",
  //               "abnormal": ""
  //           }
  //       },
  //       "other": {
  //           "normal": "",
  //           "system": "other",
  //           "abnormal": "Weight 124 kg, BMI 53.1, pitting edema in both legs"
  //       },
  //       "abdomen": {
  //           "normal": "",
  //           "abnormal": ""
  //       },
  //       "lymphatic": {
  //           "normal": "",
  //           "abnormal": ""
  //       },
  //       "urogenital": {
  //           "male": {
  //               "normal": "",
  //               "abnormal": ""
  //           },
  //           "female": {
  //               "normal": "",
  //               "abnormal": ""
  //           }
  //       },
  //       "respiratory": {
  //           "normal": "",
  //           "abnormal": "Crackles in both lungs"
  //       },
  //       "neurological": {
  //           "normal": "Appropriately oriented, fluent speech",
  //           "abnormal": ""
  //       },
  //       "cardiovascular": {
  //           "normal": "",
  //           "abnormal": "Irregular heart rhythm, systolic murmur at the base of the left sternal border"
  //       },
  //       "musculoskeletal": {
  //           "normal": "",
  //           "abnormal": "Tenderness of the knees with mild crepitus"
  //       },
  //       "generalAssessment": {
  //           "generalAppearance": {
  //               "normal": "",
  //               "abnormal": "Increased work of breathing"
  //           }
  //       }
  //   },
  //   "imagingResults": [
  //       {
  //           "name": "Chest X-ray",
  //           "sections": [
  //               {
  //                   "title": "Findings",
  //                   "normal": "",
  //                   "abnormal": [
  //                       "Enlarged cardiac silhouette",
  //                       "Low lung volumes",
  //                       "Bilateral interstitial opacities"
  //                   ]
  //               }
  //           ]
  //       },
  //       {
  //           "name": "CT Angiography of Chest",
  //           "sections": [
  //               {
  //                   "title": "Findings",
  //                   "normal": "No pulmonary emboli",
  //                   "abnormal": [
  //                       "Dilated main pulmonary artery",
  //                       "Mosaic attenuation of the lungs",
  //                       "Coronary-artery calcifications"
  //                   ]
  //               }
  //           ]
  //       },
  //       {
  //           "name": "CT Brain",
  //           "sections": [
  //               {
  //                   "title": "Findings",
  //                   "normal": "",
  //                   "abnormal": [
  //                       "Chronic lacunar infarcts",
  //                       "Microangiopathy"
  //                   ]
  //               }
  //           ]
  //       },
  //       {
  //           "name": "MRI Brain",
  //           "sections": [
  //               {
  //                   "title": "Findings",
  //                   "normal": "No acute infarction",
  //                   "abnormal": []
  //               }
  //           ]
  //       }
  //   ],
  //   "medicalHistory": {
  //       "fh": "Father had myocardial infarctions, paternal cousin had systemic lupus erythematosus",
  //       "sh": "Former smoker (2 packs/day for 15 years, quit 20 years ago), no alcohol or other substance use",
  //       "pmh": "Severe obesity, coronary-artery calcifications, hypertension, hyperlipidemia, atrial flutter, hyperparathyroidism",
  //       "psh": ""
  //   },
  //   "criticalActions": [
  //       "Initiate non-invasive ventilation (CPAP/BiPAP) to support oxygenation and ventilation",
  //       "Consult Pulmonology for urgent evaluation of pulmonary hypertension and possible CTEPH",
  //       "Perform urgent swallow evaluation to assess risk of aspiration",
  //       "Initiate workup for possible neuromuscular disorders, including anti-AChR antibody testing and neurology consultation"
  //   ],
  //   "reviewOfSystems": [
  //       {
  //           "normal": "",
  //           "system": "Constitutional",
  //           "abnormal": "Fatigue, weakness"
  //       },
  //       {
  //           "normal": "",
  //           "system": "HEENT",
  //           "abnormal": "Sore throat, dysphagia"
  //       },
  //       {
  //           "normal": "",
  //           "system": "Respiratory",
  //           "abnormal": "Dyspnea, orthopnea, intermittent nonproductive cough"
  //       },
  //       {
  //           "normal": "No chest pain, no palpitations",
  //           "system": "Cardiovascular",
  //           "abnormal": ""
  //       },
  //       {
  //           "normal": "No nausea, no abdominal pain, no constipation, no diarrhea",
  //           "system": "Gastrointestinal",
  //           "abnormal": ""
  //       },
  //       {
  //           "normal": "",
  //           "system": "Musculoskeletal",
  //           "abnormal": "Generalized weakness, limb weakness, arthritic pain"
  //       },
  //       {
  //           "normal": "No diplopia, no numbness, no dizziness, no headache",
  //           "system": "Neurological",
  //           "abnormal": "Dysarthria, daytime somnolence"
  //       },
  //       {
  //           "normal": "No urinary symptoms",
  //           "system": "Genitourinary",
  //           "abnormal": ""
  //       }
  //   ],
  //   "ruleInConditions": {
  //       "Sufficient/Pathognomonic Conditions": "Pulmonary hypertension (right ventricular systolic pressure of 60 mm Hg on echocardiogram)"
  //   },
  //   "followUpQuestions": [
  //       {
  //           "power": "A positive sleep study would be sufficient to diagnose OSA or OHS, which could explain the patient's chronic hypoxemia, pulmonary hypertension, and some neurological symptoms.",
  //           "questionTopic": "Sleep study results",
  //           "tipsToFocusOn": "Ask about snoring, witnessed apneas, and daytime sleepiness",
  //           "associatedDiagnoses": [
  //               "Obstructive Sleep Apnea",
  //               "Obesity Hypoventilation Syndrome"
  //           ]
  //       },
  //       {
  //           "power": "PFTs could help rule out or support interstitial lung disease and provide evidence for pulmonary hypertension through decreased DLCO.",
  //           "questionTopic": "Pulmonary function tests",
  //           "tipsToFocusOn": "Ask about FEV1, FVC, DLCO, and lung volumes",
  //           "associatedDiagnoses": [
  //               "Interstitial Lung Disease",
  //               "Pulmonary Hypertension"
  //           ]
  //       },
  //       {
  //           "power": "A positive anti-AChR antibody test would be highly specific for myasthenia gravis, helping to explain the episodic bulbar symptoms and generalized weakness.",
  //           "questionTopic": "Anti-acetylcholine receptor antibody test",
  //           "tipsToFocusOn": "Ask about fluctuation of symptoms throughout the day",
  //           "associatedDiagnoses": [
  //               "Myasthenia Gravis"
  //           ]
  //       },
  //       {
  //           "power": "A V/Q scan showing multiple perfusion defects would be highly suggestive of CTEPH, even in the absence of acute pulmonary emboli on CTA.",
  //           "questionTopic": "Ventilation/Perfusion (V/Q) scan",
  //           "tipsToFocusOn": "Ask about any history of deep vein thrombosis or family history of clotting disorders",
  //           "associatedDiagnoses": [
  //               "Chronic Thromboembolic Pulmonary Hypertension"
  //           ]
  //       }
  //   ],
  //   "labTestsSuggested": [
  //       {
  //           "testName": "Anti-acetylcholine receptor antibody test",
  //           "associatedDiagnoses": [
  //               "Myasthenia Gravis"
  //           ]
  //       },
  //       {
  //           "testName": "Brain natriuretic peptide (BNP)",
  //           "associatedDiagnoses": [
  //               "Pulmonary Hypertension",
  //               "Right Heart Failure"
  //           ]
  //       },
  //       {
  //           "testName": "Arterial Blood Gas",
  //           "associatedDiagnoses": [
  //               "Obesity Hypoventilation Syndrome",
  //               "Chronic Respiratory Failure"
  //           ]
  //       }
  //   ],
  //   "followUpPhysicalExam": [
  //       {
  //           "power": "Fatigable weakness is pathognomonic for myasthenia gravis and would explain the patient's dysarthria, dysphagia, and generalized weakness.",
  //           "finding": "Fatigable muscle weakness",
  //           "tipsToFocusOn": "Test for ptosis, extraocular muscle weakness, and limb muscle fatigue with repetitive movements",
  //           "associatedDiagnoses": [
  //               "Myasthenia Gravis"
  //           ]
  //       },
  //       {
  //           "power": "Upper motor neuron signs, combined with lower motor neuron symptoms, would be highly suggestive of ALS, explaining the patient's progressive weakness and bulbar symptoms.",
  //           "finding": "Upper motor neuron signs",
  //           "tipsToFocusOn": "Check for hyperreflexia, Babinski sign, and spasticity",
  //           "associatedDiagnoses": [
  //               "Amyotrophic Lateral Sclerosis (ALS)"
  //           ]
  //       },
  //       {
  //           "power": "Elevated jugular venous pressure would support right heart failure secondary to pulmonary hypertension, consistent with the echocardiogram findings.",
  //           "finding": "Jugular venous distension",
  //           "tipsToFocusOn": "Examine the neck veins with the patient at a 45-degree angle",
  //           "associatedDiagnoses": [
  //               "Right Heart Failure",
  //               "Pulmonary Hypertension"
  //           ]
  //       },
  //       {
  //           "power": "The presence of fasciculations, especially if widespread, would strongly support the diagnosis of ALS when combined with the patient's progressive weakness and bulbar symptoms.",
  //           "finding": "Fasciculations",
  //           "tipsToFocusOn": "Carefully observe relaxed muscles, particularly in the tongue and limbs",
  //           "associatedDiagnoses": [
  //               "Amyotrophic Lateral Sclerosis"
  //           ]
  //       }
  //   ],
  //   "moreSpecificDiseases": [
  //       {
  //           "disease": "Pulmonary Hypertension",
  //           "moreSpecificDiseasesConsidered": [
  //               {
  //                   "reasoning": "Elevated D-dimer, mosaic attenuation on CTA, and chronic progressive symptoms",
  //                   "probability": 70,
  //                   "moreSpecificDisease": "Chronic Thromboembolic Pulmonary Hypertension"
  //               },
  //               {
  //                   "reasoning": "Dilated pulmonary artery, right heart strain, and no clear left heart disease",
  //                   "probability": 60,
  //                   "moreSpecificDisease": "Pulmonary Arterial Hypertension"
  //               }
  //           ]
  //       }
  //   ],
  //   "occamsRazorDiagnosis": {
  //       "disease": "Chronic Thromboembolic Pulmonary Hypertension with concurrent Obstructive Sleep Apnea",
  //       "reasoning": "This combination explains the chronic progressive dyspnea, hypoxemia, pulmonary hypertension, and neurological symptoms. CTEPH accounts for the mosaic attenuation and elevated D-dimer, while OSA explains the obesity-related symptoms and contributes to pulmonary hypertension. The neurological symptoms could be due to chronic hypoxia from both conditions.",
  //       "probability": "75",
  //       "weakEvidence": "Progressive dyspnea, leg edema, daytime somnolence",
  //       "strongEvidence": "Elevated D-dimer, mosaic attenuation on CTA, pulmonary hypertension on echocardiogram, severe obesity, chronic hypoxemia",
  //       "strongContradictoryFactors": "Negative CTA for acute pulmonary emboli"
  //   },
  //   "ruleOutAnyConditions": {
  //       "Necessary Conditions": "Primary left heart failure (normal biventricular function on echocardiogram)",
  //       "Contradictory Conditions": "Acute pulmonary embolism (negative CTA)"
  //   },
  //   "DifferentialDiagnosis": [
  //       {
  //           "disease": "Chronic Thromboembolic Pulmonary Hypertension (CTEPH)",
  //           "reasoning": "CTEPH can develop over time even with negative acute CTA. The mosaic attenuation and chronic progressive symptoms are highly suggestive. The elevated D-dimer, while non-specific, supports ongoing thrombotic activity.",
  //           "probability": "70",
  //           "weakEvidence": "Progressive dyspnea, leg edema",
  //           "strongEvidence": "Elevated D-dimer, mosaic attenuation on CTA, pulmonary hypertension on echocardiogram",
  //           "strongContradictoryFactors": "Negative CTA for acute pulmonary emboli"
  //       },
  //       {
  //           "disease": "Obstructive Sleep Apnea with Obesity Hypoventilation Syndrome",
  //           "reasoning": "The patient's severe obesity, chronic hypoxemia, and daytime somnolence strongly suggest OSA with possible OHS. This could explain the pulmonary hypertension and contribute to neurological symptoms due to chronic hypoxia.",
  //           "probability": "65",
  //           "weakEvidence": "Fatigue, use of walker",
  //           "strongEvidence": "Severe obesity (BMI 53.1), daytime somnolence, chronic hypoxemia",
  //           "strongContradictoryFactors": "None"
  //       },
  //       {
  //           "disease": "Myasthenia Gravis",
  //           "reasoning": "The fluctuating bulbar symptoms (dysarthria, dysphagia) and generalized weakness are classic for myasthenia gravis. The episodic nature and progression over time fit the disease course.",
  //           "probability": "60",
  //           "weakEvidence": "Fatigue, use of walker",
  //           "strongEvidence": "Episodic dysarthria, dysphagia, generalized weakness",
  //           "strongContradictoryFactors": "None"
  //       },
  //       {
  //           "disease": "Amyotrophic Lateral Sclerosis (ALS)",
  //           "reasoning": "The combination of bulbar and limb symptoms progressing over time is concerning for ALS. However, the prominent respiratory symptoms early in the course are somewhat atypical.",
  //           "probability": "50",
  //           "weakEvidence": "Use of walker, falls",
  //           "strongEvidence": "Progressive dysarthria, dysphagia, generalized weakness",
  //           "strongContradictoryFactors": "Respiratory symptoms preceding significant limb weakness"
  //       },
  //       {
  //           "disease": "Interstitial Lung Disease",
  //           "reasoning": "The radiographic findings and chronic hypoxemia support ILD, but the normal LFTs and lack of typical risk factors (no mentioned occupational exposures or connective tissue diseases) make it less likely.",
  //           "probability": "45",
  //           "weakEvidence": "Exertional dyspnea, crackles on auscultation",
  //           "strongEvidence": "Bilateral interstitial opacities on chest radiograph, chronic hypoxemia",
  //           "strongContradictoryFactors": "Normal liver function tests (some ILDs have associated LFT abnormalities)"
  //       }
  //   ],
  //   "medicalDecisionMaking": "\n- Key findings: 72-year-old obese female with progressive dyspnea, hypoxemia, pulmonary hypertension, and episodic bulbar symptoms\n- Clinical reasoning: Chronic progressive course suggests underlying cardiopulmonary and/or neuromuscular disorder\n- Pulmonary hypertension confirmed by echocardiogram, with evidence of right heart strain\n- Mosaic attenuation on CTA and elevated D-dimer raise suspicion for CTEPH\n- Episodic dysarthria and dysphagia suggest possible neuromuscular disorder (e.g., myasthenia gravis)\n- Severe obesity and chronic hypoxemia raise concern for OSA/OHS\n- Risk stratification: High risk due to respiratory failure and potential for rapid neurological deterioration\n- Uncertainties: Exact cause of pulmonary hypertension, presence of neuromuscular disorder\n- Differential informed plan: Address respiratory failure, evaluate for CTEPH and neuromuscular disorders",
  //   "imagingStudiesSuggested": [
  //       {
  //           "study": "Ventilation/Perfusion (V/Q) scan",
  //           "associatedDiagnoses": [
  //               "Chronic Thromboembolic Pulmonary Hypertension"
  //           ]
  //       },
  //       {
  //           "study": "Right Heart Catheterization",
  //           "associatedDiagnoses": [
  //               "Pulmonary Hypertension"
  //           ]
  //       },
  //       {
  //           "study": "Polysomnography",
  //           "associatedDiagnoses": [
  //               "Obstructive Sleep Apnea",
  //               "Obesity Hypoventilation Syndrome"
  //           ]
  //       }
  //   ],
  //   "evaluateEvidenceStrength": {
  //       "Weak Evidence": "Family history of SLE (possible autoimmune connection), history of falls (could be due to various causes).",
  //       "Strong Evidence": "Echocardiogram showing pulmonary hypertension, chronic hypoxemia, and progressive neurological symptoms."
  //   },
  //   "assessSupportiveConditions": {
  //       "Direct Support Conditions": "Chronic hypoxemia, dilated pulmonary artery on CTA, and tricuspid regurgitation support pulmonary hypertension. Dysarthria, dysphagia, and generalized weakness support a neuromuscular disorder.",
  //       "Indirect Support Conditions": "Obesity, daytime somnolence, and chronic hypoxemia support obstructive sleep apnea or obesity hypoventilation syndrome."
  //   },
  //   "clinicalDecisionToolsResults": [
  //       {
  //           "name": "Wells' Criteria for Pulmonary Embolism",
  //           "values": [
  //               {
  //                   "name": "Score",
  //                   "value": "Not provided",
  //                   "abnormal": false
  //               }
  //           ]
  //       },
  //       {
  //           "name": "CURB-65 for Pneumonia Severity",
  //           "values": [
  //               {
  //                   "name": "Score",
  //                   "value": "Not applicable",
  //                   "abnormal": false
  //               }
  //           ]
  //       },
  //       {
  //           "name": "CHA2DS2-VASc Score",
  //           "values": [
  //               {
  //                   "name": "Score",
  //                   "value": "Not provided",
  //                   "abnormal": false
  //               }
  //           ]
  //       }
  //   ],
  //   "clinicalDecisionToolsSuggested": [
  //       {
  //           "tool": "Epworth Sleepiness Scale",
  //           "associatedDiagnoses": [
  //               "Obstructive Sleep Apnea"
  //           ]
  //       },
  //       {
  //           "tool": "STOP-BANG Questionnaire",
  //           "associatedDiagnoses": [
  //               "Obstructive Sleep Apnea"
  //           ]
  //       },
  //       {
  //           "tool": "Geneva Score for Pulmonary Embolism",
  //           "associatedDiagnoses": [
  //               "Chronic Thromboembolic Pulmonary Hypertension"
  //           ]
  //       }
  //   ],
  //   "criticalOfDiseasesWithContradictoryEvidence": [
  //       {
  //           "disease": "Congestive Heart Failure",
  //           "newDiseasesConsidered": [
  //               {
  //                   "reasoning": "Normal left ventricular function but signs of right heart strain and pulmonary hypertension",
  //                   "newDisease": "Right Heart Failure secondary to Pulmonary Hypertension",
  //                   "probability": 85
  //               }
  //           ]
  //       }
  //   ]
}