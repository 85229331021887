import React from 'react';
import { Link } from 'react-router-dom';
import { 
  UserCircle, 
  HelpCircle,
  UserPlus, 
  FileText
} from 'lucide-react';

interface TopBarProps {
  onShowNotePopup: () => void;
}

const TopBar: React.FC<TopBarProps> = ({ onShowNotePopup }) => {
  return (
    <div className="h-14 bg-hover dark:bg-tesla-dark-surface border-b border-tesla-lightGray dark:border-tesla-dark-border flex items-center justify-between px-4">
      {/* Left side - Logo and Notes */}
      <div className="flex items-center space-x-4">
        <Link to="/home" className="text-xl font-bold text-tesla-blue dark:text-tesla-dark-text">
          Foundation
        </Link>
        <Link 
          to="/notewriter" 
          className="p-2 hover:bg-gray-100 dark:hover:bg-tesla-dark-border/20 rounded-full text-tesla-black dark:text-tesla-dark-text"
          title="Clinical Notes"
        >
          <FileText size={20} />
        </Link>
      </div>

      {/* Right side - Icons */}
      <div className="flex items-center space-x-4">
        <Link to="/home" className="p-2 hover:bg-gray-100 dark:hover:bg-tesla-dark-border/20 rounded-full text-tesla-black dark:text-tesla-dark-text" title="New Patient">
          <UserPlus size={20} />
        </Link>
        <Link to="/help" className="p-2 hover:bg-gray-100 dark:hover:bg-tesla-dark-border/20 rounded-full text-tesla-black dark:text-tesla-dark-text" title="Help">
          <HelpCircle size={20} />
        </Link>
        <Link to="/profile" className="p-2 hover:bg-gray-100 dark:hover:bg-tesla-dark-border/20 rounded-full text-tesla-black dark:text-tesla-dark-text" title="Profile">
          <UserCircle size={20} />
        </Link>
      </div>
    </div>
  );
};

export default TopBar; 