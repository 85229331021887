import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { DifferentialDiagnosis } from '../model/EncounterData';
import KeyValuePair from './KeyValuePair';
import { isEmpty } from '../util/empty';

interface DiagnosisCollapsibleProps {
  diagnosis: DifferentialDiagnosis;
  expandAll?: boolean;
}

const DiagnosisCollapsible: React.FC<DiagnosisCollapsibleProps> = ({ diagnosis, expandAll }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (expandAll !== undefined) {
      setIsExpanded(expandAll);
    }
  }, [expandAll]);

  if (isEmpty(diagnosis)) return null;

  // Add this helper function inside the component
  const getColorForProbability = (probability: number) => {
    // Convert hex to RGB
    const startColor = { r: 43, g: 0, b: 255 };    // #2b00ff
    const endColor = { r: 255, g: 0, b: 102 };     // #ff0066
    
    // Linear interpolation
    const r = Math.round(startColor.r + (endColor.r - startColor.r) * (probability / 100));
    const g = Math.round(startColor.g + (endColor.g - startColor.g) * (probability / 100));
    const b = Math.round(startColor.b + (endColor.b - startColor.b) * (probability / 100));
    
    return `rgb(${r}, ${g}, ${b})`;
  };

  return (
    <div className="border-b border-tesla-border dark:border-tesla-dark-border last:border-b-0">
      {/* Clean, minimal header for diagnosis */}
      <div
        className="flex justify-between items-center py-3 px-4 cursor-pointer hover:bg-tesla-hover/30 dark:hover:bg-tesla-dark-border/20 transition-colors"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center gap-4">
          {isExpanded ? (
            <ChevronUp size={16} className="text-tesla-gray dark:text-tesla-dark-text/70" />
          ) : (
            <ChevronDown size={16} className="text-tesla-gray dark:text-tesla-dark-text/70" />
          )}
          <span className="font-gotham text-lg text-tesla-black dark:text-tesla-dark-text">
            {diagnosis.disease}
          </span>
        </div>
        <span 
          className="text-sm font-gotham" 
          style={{ color: getColorForProbability(diagnosis.probability ?? 0) }}
        >
          {diagnosis.probability}%
        </span>
      </div>

      {/* Expanded content */}
      {isExpanded && (
        <div className="py-3 px-4 pl-10 space-y-3 bg-tesla-hover/10 dark:bg-tesla-dark-surface/50">
          <KeyValuePair 
            label="For" 
            value={diagnosis.strongEvidence} 
            labelColor="tesla-green dark:text-green-400"
          />
          <KeyValuePair 
            label="Neutral" 
            value={diagnosis.weakEvidence} 
            labelColor="tesla-gray dark:text-tesla-dark-text/70" 
          />
          <KeyValuePair 
            label="Against" 
            value={diagnosis.strongContradictoryFactors} 
            labelColor="tesla-red dark:text-tesla-red" 
          />
          <KeyValuePair 
            label="Reasoning" 
            value={diagnosis.reasoning} 
            labelColor="primary dark:text-primary"
          />
        </div>
      )}
    </div>
  );
};

export default DiagnosisCollapsible;
