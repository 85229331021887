import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Register from './pages/Register';
import Login from './pages/Login';
import Home from './pages/Home';
import EncounterPage from './pages/EncounterPage';
import useViewportHeight from './hooks/useViewportHeight';
import { AuthOutlet } from './components/AuthOutlet';
import ProfilePage from './pages/ProfilePage';
import HelpPage from './pages/HelpPage';
import NoteWriter from './pages/NoteWriter';
import DebugPage from './pages/DebugPage';
import { DarkModeProvider } from './contexts/DarkModeContext';
import { AuthProvider } from './contexts/AuthContext';

function App() {
  useViewportHeight();
  return (
    <Router>
      <AuthProvider>
        <DarkModeProvider>
          <div className="App dark:bg-tesla-dark-bg dark:text-tesla-dark-text">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/debug" element={<DebugPage />} />

              <Route element={<AuthOutlet fallbackPath="/login" />}>
                <Route path="/home" element={<Home />} />
                <Route path="/encounter/:encounterId" element={<EncounterPage />} />
                <Route path="/notewriter" element={<NoteWriter />} />
              </Route>

              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/help" element={<HelpPage />} />
            </Routes>
          </div>
        </DarkModeProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
