import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Message, Encounter } from '../hooks/apis';
import Chat from './Chat';
import SectionHeader from './SectionHeader';
import PlanSections from './PlanSections';
import EncounterSummarySections from './EncounterSummarySections';
import AnalysisSections from './AnalysisSections';

interface EncounterUIMobileProps {
  encounter: Encounter | undefined;
  messages: Message[];
  isAnalysisLoading: boolean;
  onNewMessage: (message: string) => void;
  onDeleteMessage: (messageId: string) => void;
  sendAudioData: (recordingId: string, segmentNumber: number, data: Blob, mimeType: string) => Promise<void>;
  sendAudioFinished: (recordingId: string, segmentCount: number) => Promise<void>;
  finishedTranscription: boolean;
}

const EncounterUIMobile: React.FC<EncounterUIMobileProps> = ({
  encounter,
  messages,
  isAnalysisLoading,
  onNewMessage,
  onDeleteMessage,
  sendAudioData,
  sendAudioFinished,
  finishedTranscription
}) => {
  const [showChat, setShowChat] = useState(false);
  const [chatHeight, setChatHeight] = useState(40);
  const [isResizingChat, setIsResizingChat] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const chatResizeStart = useRef(0);
  const initialChatHeight = useRef(0);

  const handleChatResizeStart = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsResizingChat(true);
    chatResizeStart.current = e.clientY;
    initialChatHeight.current = chatHeight;
  };

  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});
  const [expandAll, setExpandAll] = useState<boolean | undefined>(undefined);

  const toggleExpandAll = () => {
    setExpandAll(prev => !prev);
  };

  const handleCheckboxChange = (id: string) => {
    setCheckedItems(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isResizingChat || !containerRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const mouseY = e.clientY - containerRect.top;
      const containerHeight = containerRect.height;

      const percentage = (mouseY / containerHeight) * 100;
      const newHeight = Math.min(Math.max(percentage, 0), 70);

      setChatHeight(100 - newHeight);
    };

    const handleMouseUp = () => {
      setIsResizingChat(false);
    };

    // Handle touch events for mobile
    const handleTouchMove = (e: TouchEvent) => {
      if (!isResizingChat || !containerRef.current) return;

      const touch = e.touches[0];
      const containerRect = containerRef.current.getBoundingClientRect();
      const touchY = touch.clientY - containerRect.top;
      const containerHeight = containerRect.height;

      const percentage = (touchY / containerHeight) * 100;
      const newHeight = Math.min(Math.max(percentage, 0), 70);

      setChatHeight(100 - newHeight);
    };

    if (isResizingChat) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('touchmove', handleTouchMove);
      window.addEventListener('touchend', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleMouseUp);
    };
  }, [isResizingChat]);

  return (
    <div ref={containerRef} className="flex flex-col h-full relative">
      {/* Plan Section */}
      <div
        className="flex-grow overflow-y-auto relative"
        style={{ height: `${showChat ? `${100 - chatHeight}%` : 'calc(100% - 83px)'}` }}
      >
        <div className="h-full w-full flex flex-col">
          <SectionHeader
            title="Information"
            isLoading={isAnalysisLoading}
            expandAll={expandAll}
            onToggleExpandAll={toggleExpandAll}
          />

          {/* Scrollable Content */}
          <div className="flex-1 overflow-y-auto p-6 pt-2 bg-tesla-hover dark:bg-tesla-dark-bg">
            <PlanSections encounter={encounter} expandAll={expandAll} checkedItems={checkedItems} onCheckboxChange={handleCheckboxChange} />
            <AnalysisSections encounter={encounter} expandAll={expandAll} />
            <EncounterSummarySections encounter={encounter} expandAll={expandAll} />
          </div>
        </div>
        <button
          className="absolute bottom-4 right-4 z-10 p-2 bg-hover dark:bg-tesla-dark-surface rounded-full shadow-md hover:bg-gray-50 dark:hover:bg-tesla-dark-border/20 text-[#02b6eb] dark:text-[#02b6eb]/70 border border-[#02b6eb] dark:border-[#02b6eb]/70"
          onClick={() => setShowChat(!showChat)}
        >
          {showChat ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
        </button>
      </div>

      {/* Chat Section */}
      <div
        className={`relative border-t border-tesla-lightGray dark:border-tesla-dark-border ${showChat ? '' : 'h-[120px]'}`}
        style={{ height: showChat ? `${chatHeight}%` : undefined }}
      >
        {/* Resize Handle */}
        <div
          className="absolute top-0 left-0 right-0 h-1.5 bg-tesla-lightGray/30 dark:bg-tesla-dark-border/30 hover:bg-tesla-lightGray dark:hover:bg-tesla-dark-border cursor-row-resize"
          onMouseDown={handleChatResizeStart}
          onTouchStart={(e) => {
            e.preventDefault();
            handleChatResizeStart(e as unknown as React.MouseEvent);
          }}
        />
        <Chat
          messages={messages}
          onNewMessage={onNewMessage}
          onDeleteMessage={onDeleteMessage}
          sendAudioData={sendAudioData}
          sendAudioFinished={sendAudioFinished}
          finishedTranscription={finishedTranscription}
        />
      </div>

      {/* Resize overlay */}
      {isResizingChat && (
        <div className="fixed inset-0 z-50 cursor-row-resize" />
      )}
    </div>
  );
};

export default EncounterUIMobile; 