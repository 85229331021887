const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

async function fetchWithAuth(url: string, options: RequestInit = {}) {
  const token = localStorage.getItem('token');

  const headers = {
    'Content-Type': 'application/json',
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    ...options.headers,
  };

  const response = await fetch(`${BASE_URL}${url}`, {
    ...options,
    headers,
    credentials: 'omit',
  });

  if (!response.ok) {
    const errorText = await response.text();
    console.error('Fetch error:', response.status, response.statusText, errorText);
    throw new Error(`Fetch error: ${response.status} ${response.statusText}`);
  }

  return response;
}

export const api = {
  get: (url: string) => fetchWithAuth(url),

  post: (url: string, data: any) =>
    fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(data),
    }),

  put: (url: string, data: any) =>
    fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(data),
    }),

  patch: async (url: string, data: any) => {
    console.log('PATCH request to:', url, 'with data:', data);
    return fetchWithAuth(url, {
      method: 'PATCH',
      body: JSON.stringify(data),
    });
  },

  delete: (url: string) =>
    fetchWithAuth(url, {
      method: 'DELETE',
    }),
};