import React, { createContext, useContext, useEffect, useState } from 'react';

type ThemeMode = 'system' | 'light' | 'dark';

interface DarkModeContextType {
  darkMode: boolean;
  themePreference: ThemeMode;
  setThemePreference: (mode: ThemeMode) => void;
}

const DarkModeContext = createContext<DarkModeContextType | undefined>(undefined);

export const DarkModeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [themePreference, setThemePreference] = useState<ThemeMode>(() => {
    const saved = localStorage.getItem('themePreference');
    return (saved as ThemeMode) || 'system';
  });
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const updateTheme = () => {
      if (themePreference === 'system') {
        const systemDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setDarkMode(systemDark);
        document.documentElement.classList.toggle('dark', systemDark);
      } else {
        setDarkMode(themePreference === 'dark');
        document.documentElement.classList.toggle('dark', themePreference === 'dark');
      }
    };

    updateTheme();
    localStorage.setItem('themePreference', themePreference);

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', updateTheme);

    return () => mediaQuery.removeEventListener('change', updateTheme);
  }, [themePreference]);

  return (
    <DarkModeContext.Provider value={{ darkMode, themePreference, setThemePreference }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const useDarkMode = () => {
  const context = useContext(DarkModeContext);
  if (context === undefined) {
    throw new Error('useDarkMode must be used within a DarkModeProvider');
  }
  return context;
}; 