import React from 'react';
import { Encounter } from '../hooks/apis';
import CollapsibleSection from './CollapsibleSection';
import KeyValuePair from './KeyValuePair';
import FollowUpCollapsible from './FollowUpCollapsible';

interface PlanSectionsProps {
  encounter?: Encounter;
  expandAll?: boolean;
  checkedItems?: Record<string, boolean>;
  onCheckboxChange?: (id: string) => void;
}

const PlanSections: React.FC<PlanSectionsProps> = ({
  encounter,
  expandAll,
  checkedItems,
  onCheckboxChange,
}) => {
  const encounterData = encounter?.data;

  const checkboxClass = `
    appearance-none
    h-4 w-4 rounded border-tesla-black dark:border-tesla-dark-text border 
    checked:bg-tesla-gray/50
    checked:hover:bg-tesla-gray/50
    checked:border-tesla-gray/50
    focus:ring-tesla-black dark:focus:ring-tesla-dark-text focus:ring-1
    hover:border-tesla-black dark:hover:border-tesla-dark-text
    relative
    before:content-['✓']
    before:hidden
    checked:before:block
    before:absolute
    before:text-white
    before:text-xs
    before:top-0
    before:left-[2px]
  `;

  const checkedTextClass = 'text-tesla-gray/50 dark:text-tesla-dark-text/50';

  return (
    <>
      <CollapsibleSection
        title="High Yield Questions"
        expandAll={expandAll}
        emptyCheck={encounterData?.followUpQuestions}
      >
        <div className="mt-2">
          {encounterData?.followUpQuestions?.map((question, index) => (
            <FollowUpCollapsible
              key={index}
              item={question}
              expandAll={expandAll}
              type="question"
            />
          ))}
        </div>
      </CollapsibleSection>

      <CollapsibleSection
        title="High Yield Exam"
        expandAll={expandAll}
        emptyCheck={encounterData?.followUpPhysicalExam}
      >
        <div className="mt-2">
          {encounterData?.followUpPhysicalExam?.map((exam, index) => (
            <FollowUpCollapsible
              key={index}
              item={exam}
              expandAll={expandAll}
              type="exam"
            />
          ))}
        </div>
      </CollapsibleSection>

      <CollapsibleSection
        title="Orders"
        expandAll={expandAll}
        emptyCheck={{
          labTestsSuggested: encounterData?.labTestsSuggested,
          imagingStudiesSuggested: encounterData?.imagingStudiesSuggested
        }}
      >
        <div className="space-y-4">
          <div>
            <h4 className="font-semibold text-lg text-tesla-black dark:text-tesla-dark-text mb-2">Lab Tests</h4>
            {encounterData?.labTestsSuggested?.map((test, index) => (
              <div key={index} className="mb-2 flex items-start gap-2">
                <input
                  type="checkbox"
                  checked={checkedItems?.[`lab-${index}`] || false}
                  onChange={() => onCheckboxChange?.(`lab-${index}`)}
                  className={`mt-1 ${checkboxClass}`}
                />
                <div className={checkedItems?.[`lab-${index}`] ? checkedTextClass : ''}>
                  <p className="text-sm font-bold">{test.testName}</p>
                  <KeyValuePair
                    value={test.associatedDiagnoses?.join(', ')}
                    compact
                    textColor={checkedItems?.[`lab-${index}`] ? 'tesla-gray/50 dark:text-tesla-dark-text/30' : 'tesla-gray dark:text-tesla-dark-text/70'}
                  />
                </div>
              </div>
            ))}
          </div>

          <div>
            <h4 className="font-semibold text-lg text-tesla-black dark:text-tesla-dark-text mb-2">Imaging Studies</h4>
            {encounterData?.imagingStudiesSuggested?.map((study, index) => (
              <div key={index} className="mb-2 flex items-start gap-2">
                <input
                  type="checkbox"
                  checked={checkedItems?.[`imaging-${index}`] || false}
                  onChange={() => onCheckboxChange?.(`imaging-${index}`)}
                  className={`mt-1 ${checkboxClass}`}
                />
                <div className={checkedItems?.[`imaging-${index}`] ? checkedTextClass : ''}>
                  <p className="text-sm font-bold">{study.study}</p>
                  <KeyValuePair
                    value={study.associatedDiagnoses?.join(', ')}
                    compact
                    textColor={checkedItems?.[`imaging-${index}`] ? 'tesla-gray/50 dark:text-tesla-dark-text/30' : 'tesla-gray dark:text-tesla-dark-text/70'}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </CollapsibleSection>

      <CollapsibleSection
        title="CRITICAL ACTIONS"
        defaultExpanded={true}
        expandAll={expandAll}
        emptyCheck={encounterData?.criticalActions}
      >
        <ul className="list-none pl-5 text-gray-700 dark:text-tesla-dark-text">
          {encounterData?.criticalActions?.map((action, index) => (
            <li key={index} className="text-base flex items-center gap-2">
              <input
                type="checkbox"
                checked={checkedItems?.[`critical-${index}`] || false}
                onChange={() => onCheckboxChange?.(`critical-${index}`)}
                className={checkboxClass}
              />
              <span className={`font-bold ${
                checkedItems?.[`critical-${index}`] 
                  ? checkedTextClass 
                  : 'text-tesla-black dark:text-tesla-dark-text'
              }`}>
                {action}
              </span>
            </li>
          ))}
        </ul>
      </CollapsibleSection>
      
      <CollapsibleSection
        title="Clinical Decision Tools"
        expandAll={expandAll}
        emptyCheck={encounterData?.clinicalDecisionToolsSuggested}
      >
        <div className="space-y-2">
          {encounterData?.clinicalDecisionToolsSuggested?.map((tool, index) => (
            <div key={index} className="mb-2">
              <p className="text-tesla-black dark:text-tesla-dark-text text-sm font-bold">
                {tool.tool}
              </p>
              <KeyValuePair
                value={tool.associatedDiagnoses?.join(', ')}
                compact
                textColor="tesla-gray dark:text-tesla-dark-text/70"
              />
            </div>
          ))}
        </div>
      </CollapsibleSection>
    </>
  );
};

export default PlanSections; 