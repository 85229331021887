import React, { createContext, useContext, useState, useEffect } from 'react';
import { api } from '../util/api';

interface User {
  id: string;
  first_name?: string;
  firstName?: string;
  last_name?: string;
  lastName?: string;
  email: string;
  data?: {
    preferences?: string | {
      specialty: string;
      note_type: string;
      language: string;
      timezone: string;
      hospital_name: string;
      hospital_contact: string;
      system_prompt: string;
    };
  };
}

interface AuthContextType {
  user: User | null;
  setUser: (user: User | null) => void;
  loading: boolean;
  refreshUser: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const refreshUser = async () => {
    try {
      const response = await api.get('/user/me');
      if (response.ok) {
        const userData = await response.json();
        console.log('Refreshed user data:', userData);
        
        if (userData.data?.preferences && typeof userData.data.preferences === 'string') {
          try {
            userData.data.preferences = JSON.parse(userData.data.preferences);
          } catch (e) {
            console.error('Error parsing preferences:', e);
          }
        }
        
        setUser(userData);
      } else {
        console.error('Failed to refresh user:', await response.text());
      }
    } catch (error) {
      console.error('Error refreshing user:', error);
    }
  };

  useEffect(() => {
    refreshUser().finally(() => setLoading(false));
  }, []);

  const value = {
    user,
    setUser,
    loading,
    refreshUser
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
} 