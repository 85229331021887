import React from 'react';

interface KeyValuePairProps {
  label?: string;
  value?: string | string[];
  compact?: boolean;
  labelColor?: string;
  textColor?: string;
}

const KeyValuePair: React.FC<KeyValuePairProps> = ({
  label,
  value,
  compact = false,
  labelColor = "tesla-black dark:text-tesla-dark-text",
  textColor = "tesla-gray dark:text-tesla-dark-text/70"
}) => {
  if (!value) return null;

  const values = Array.isArray(value) ? value : [value];
  
  return (
    <div className={compact ? "" : "mb-2"}>
      {label && (
        <span className={`font-medium text-${labelColor}`}>
          {label}:&nbsp;
        </span>
      )}
      <span className={`text-${textColor}`}>
        {values.join(", ")}
      </span>
    </div>
  );
};

export default KeyValuePair;
