import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { isEmpty } from '../util/empty';

interface CollapsibleSectionProps {
  title: React.ReactNode | string;
  children: React.ReactNode;
  defaultExpanded?: boolean;
  expandAll?: boolean;
  emptyCheck?: unknown;
  className?: string;
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  children,
  defaultExpanded = true,
  expandAll,
  emptyCheck,
  className = "mb-6"
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  useEffect(() => {
    if (expandAll !== undefined) {
      setIsExpanded(expandAll);
    }
  }, [expandAll]);

  if (isEmpty(emptyCheck)) {
    return null;
  }

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className={className}>
      {/* Header with rounded corners and grey background */}
      <div 
        className="flex justify-between items-center py-4 px-6 bg-tesla-lightGray dark:bg-tesla-dark-surface cursor-pointer hover:bg-tesla-border/20 dark:hover:bg-tesla-dark-border/20 transition-colors rounded-md"
        onClick={toggleExpand}
      >
        {React.isValidElement(title) ? (
          title
        ) : (
          <h3 className="font-gotham text-lg text-tesla-black dark:text-tesla-dark-text">{title}</h3>
        )}
        {isExpanded ? (
          <ChevronUp size={20} className="text-tesla-gray dark:text-tesla-dark-text/70" />
        ) : (
          <ChevronDown size={20} className="text-tesla-gray dark:text-tesla-dark-text/70" />
        )}
      </div>
      
      {/* Content Section */}
      {isExpanded && (
        <div className="py-4 px-6 bg-tesla-hover dark:bg-tesla-dark-bg">
          {children}
        </div>
      )}
    </div>
  );
};

export default CollapsibleSection;
