import React, { useState, useRef, useEffect } from 'react';
import { Send, User, Mic, Trash2, Copy } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { Message } from '../hooks/apis';
import Recording from './Recording';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import EmmaAvatar from '../assets/EMMA-avatar.jpg';

interface ChatProps {
  messages: Message[];
  onNewMessage: (message: string) => void;
  onDeleteMessage: (messageId: string) => void;
  sendAudioData: (recordingId: string, segmentNumber: number, data: Blob, mimeType: string) => Promise<void>;
  sendAudioFinished: (recordingId: string, segmentCount: number) => Promise<void>;
  finishedTranscription: boolean;
}

const Chat: React.FC<ChatProps> = ({
  messages,
  onNewMessage,
  onDeleteMessage,
  sendAudioData,
  sendAudioFinished,
  finishedTranscription
}) => {
  const [input, setInput] = useState<string>('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isRecording, setIsRecording] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (input.trim()) {
      console.log('Submitting message:', input);
      onNewMessage(input);
      setInput('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (input.trim()) {
        console.log('Submitting message via Enter key:', input);
        onNewMessage(input);
        setInput('');
      }
    }
  };

  useEffect(() => {
    if (location.state?.startRecording) {
      setIsRecording(true);
    }
    // Clear the state after using it
    if (location.state) {
      navigate(location.pathname, { replace: true, state: {} });
    }
    // we only want to run this once
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (finishedTranscription) {
      setIsRecording(false);
    }
  }, [finishedTranscription]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (!isRecording) {
      textareaRef.current?.focus();
    }
  }, [isRecording]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Reset height to allow shrinking
      textarea.style.height = 'auto';
      // Set new height based on content
      textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [input]);

  // const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   setInput(e.target.value);
  //   adjustTextareaHeight();
  // };

  useEffect(() => {
    console.log('Messages updated:', messages);
  }, [messages]);

  const handleCopy = async (content: string) => {
    try {
      await navigator.clipboard.writeText(content);
      // Optionally add some visual feedback that copying succeeded
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  // Chatbox component
  return (
    <div className="flex flex-col h-full w-full bg-tesla-hover dark:bg-black">
      <div className="flex-1 overflow-hidden p-4 bg-hover dark:bg-black">
        <div className="h-full overflow-y-auto p-6 space-y-4">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`group flex items-start space-x-2 max-w-3/4 ${message.role === 'user' ? 'justify-end ml-auto' : ''}`}
            >
              {message.role === 'user' ? (
                <>
                  <button
                    onClick={() => onDeleteMessage(message.id)}
                    className="opacity-0 group-hover:opacity-100 transition-opacity p-1 text-red-500 hover:text-red-700"
                    title="Delete message"
                  >
                    <Trash2 size={16} />
                  </button>
                  <div className="whitespace-pre-wrap p-3 rounded-md bg-gray-100 dark:bg-black text-tesla-black dark:text-tesla-dark-text text-sm">
                    {message.data.content}
                  </div>
                  <User className="text-tesla-gray dark:text-tesla-dark-text/70 mt-1" size={24} />
                </>
              ) : (
                <>
                  <img 
                    src={EmmaAvatar} 
                    alt="EMMA Avatar" 
                    className="w-8 h-8 rounded-full mt-1 object-cover"
                  />
                  <div className="prose prose-sm max-w-none p-3 rounded-md bg-white dark:bg-tesla-dark-surface text-[#02b6eb] dark:text-[#02b6eb]/90 border border-gray-200 dark:border-tesla-dark-border">
                    <ReactMarkdown>{message.data.content}</ReactMarkdown>
                  </div>
                  <div className="flex flex-col gap-1">
                    <button
                      onClick={() => handleCopy(message.data.content)}
                      className="opacity-0 group-hover:opacity-100 transition-opacity p-1 text-[#02b6eb] hover:text-[#02b6eb]/70"
                      title="Copy message"
                    >
                      <Copy size={16} />
                    </button>
                    <button
                      onClick={() => onDeleteMessage(message.id)}
                      className="opacity-0 group-hover:opacity-100 transition-opacity p-1 text-red-500 hover:text-red-700"
                      title="Delete message"
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                </>
              )}
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="bg-hover dark:bg-black border-t border-gray-100 dark:border-tesla-dark-border p-4">
        <form onSubmit={handleSubmit} className="flex flex-col space-y-3 max-w-4xl mx-auto">
          <div className="flex items-center space-x-2">
            {!isRecording ? (
              <div className="flex flex-grow items-center border border-[#02b6eb] dark:border-[#02b6eb]/70 rounded-md focus-within:ring-1 focus-within:ring-[#02b6eb] dark:focus-within:ring-[#02b6eb]/70">
                <textarea
                  ref={textareaRef}
                  autoFocus
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Do you have more patient information for me?"
                  rows={1}
                  className="flex-grow p-3 border-none rounded-l-md focus:outline-none 
                    bg-white dark:bg-black
                    text-tesla-black dark:text-tesla-dark-text 
                    disabled:bg-gray-50 dark:disabled:bg-tesla-dark-border/20 
                    disabled:text-tesla-lightGray dark:disabled:text-tesla-dark-text/50 
                    resize-none min-h-[44px] max-h-[200px] overflow-y-auto 
                    placeholder:text-[#02b6eb] dark:placeholder:text-[#02b6eb]/70"
                />
                <button
                  type="submit"
                  className="p-3 bg-white dark:bg-black text-[#02b6eb] dark:text-[#02b6eb]/70 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200 self-end"
                >
                  <Send size={20} />
                </button>
              </div>
            ) : (
              <Recording sendAudioData={sendAudioData} sendAudioFinished={sendAudioFinished} />
            )}

            {!isRecording && (
              <button
                type="button"
                onClick={() => setIsRecording(true)}
                className="p-3 bg-white dark:bg-black text-red-500 dark:text-red-400 
                  hover:text-red-700 dark:hover:text-red-300 
                  rounded-md border border-red-500 dark:border-red-400 
                  hover:border-red-700 dark:hover:border-red-300 
                  focus:outline-none focus:ring-2 focus:ring-red-500 dark:focus:ring-red-400 
                  focus:ring-offset-2 dark:focus:ring-offset-tesla-dark-bg 
                  disabled:opacity-50 disabled:cursor-not-allowed 
                  transition-colors duration-200"
              >
                <Mic size={20} />
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Chat;
