import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { useCheckEmailApi, useRegisterApi } from '../hooks/apis';
import { ArrowRight, Loader2 } from 'lucide-react'; // Add Loader2 import
import isEmail from 'validator/lib/isEmail';
import InputWithValidation from '../components/InputWithValidation';

interface RegistrationForm {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

const Register: React.FC = () => {
  const [form, setForm] = useState<RegistrationForm>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });
  const [error, setError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [firstNameError, setFirstNameError] = useState<string | null>(null);
  const [lastNameError, setLastNameError] = useState<string | null>(null);
  const [touchedFields, setTouchedFields] = useState<Set<string>>(new Set());
  const navigate = useNavigate();

  const { checkEmailApi, isLoading: isCheckingEmail } = useCheckEmailApi();
  const { registerApi, isLoading: isRegistering } = useRegisterApi(); // Add isLoading

  const checkEmail = useCallback( // eslint-disable-line react-hooks/exhaustive-deps
    debounce(async (email: string) => {
      const { data, error } = await checkEmailApi(email);
      if (error) {
        setEmailError('Error checking email availability');
      } else if (data) {
        setEmailError(data.emailTaken ? 'Email is already taken' : null);
      }
    }, 300),
    [checkEmailApi, setEmailError]
  );

  const validatePassword = (password: string) => {
    const errors = [];
    if (password.length < 8) {
      errors.push('At least 8 characters long');
    }
    if (!/\d/.test(password)) {
      errors.push('Contains at least 1 number');
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push('Contains at least 1 symbol');
    }
    return errors;
  };

  const validateName = (name: string, fieldName: string) => {
    if (!name.trim()) {
      return `${fieldName} is required`;
    }
    return null;
  };

  const validateEmail = (email: string) => {
    if (!email.trim()) {
      return 'Email is required';
    }
    if (!isEmail(email)) {
      return 'Invalid email format';
    }
    return null;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm(prevForm => ({ ...prevForm, [name]: value }));
    setTouchedFields(prev => new Set(prev).add(name));

    if (name === 'firstName') {
      setFirstNameError(validateName(value, 'First name'));
    } else if (name === 'lastName') {
      setLastNameError(validateName(value, 'Last name'));
    } else if (name === 'email') {
      const currentEmailError = validateEmail(value);
      if (currentEmailError) {
        setEmailError(currentEmailError);
      } else {
        checkEmail(value);
      }
    } else if (name === 'password') {
      setPasswordErrors(validatePassword(value));
    }

  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setTouchedFields(new Set(['firstName', 'lastName', 'email', 'password']));

    const currentFirstNameError = validateName(form.firstName, 'First name');
    const currentLastNameError = validateName(form.lastName, 'Last name');
    let currentEmailError = emailError;
    if (!currentEmailError) {
      currentEmailError = validateEmail(form.email);
    }
    const currentPasswordErrors = validatePassword(form.password);
    setFirstNameError(currentFirstNameError);
    setLastNameError(currentLastNameError);
    setEmailError(currentEmailError);
    setPasswordErrors(currentPasswordErrors);

    if (currentFirstNameError || currentLastNameError || currentEmailError || currentPasswordErrors.length > 0 || currentEmailError) {
      return;
    }

    const { data, error } = await registerApi({
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      password: form.password
    });

    if (error) {
      setError('An error occurred during registration');
    } else if (data) {
      localStorage.setItem('token', data.accessToken);
      navigate('/home');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-600 via-blue-700 to-purple-700 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
          Create your account
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {error && (
            <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          <form className="space-y-6" onSubmit={handleSubmit} noValidate>
            <InputWithValidation
              name="firstName"
              label="First Name"
              type="text"
              autoComplete="given-name"
              validationLoading={false}
              error={firstNameError}
              value={form.firstName}
              onChange={handleChange}
              showValidation={touchedFields.has('firstName')}
            />
            <InputWithValidation
              name="lastName"
              label="Last Name"
              type="text"
              autoComplete="family-name"
              validationLoading={false}
              error={lastNameError}
              value={form.lastName}
              onChange={handleChange}
              showValidation={touchedFields.has('lastName')}
            />
            <InputWithValidation
              name="email"
              label="Email address"
              type="email"
              autoComplete="email"
              validationLoading={isCheckingEmail}
              error={emailError}
              value={form.email}
              onChange={handleChange}
              showValidation={touchedFields.has('email')}
            />
            <InputWithValidation
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              autoComplete="new-password"
              validationLoading={false}
              error={passwordErrors}
              value={form.password}
              onChange={handleChange}
              showValidation={touchedFields.has('password')}
            />

            {/* Show password checkbox */}
            <div className="mt-2 flex items-center">
              <input
                id="show-password"
                name="show-password"
                type="checkbox"
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label htmlFor="show-password" className="ml-2 block text-sm text-gray-900">
                Show password
              </label>
            </div>

            <div>
              <button
                type="submit"
                disabled={isRegistering}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isRegistering ? (
                  <>
                    <Loader2 className="animate-spin mr-2 h-5 w-5" />
                    Registering...
                  </>
                ) : (
                  <>
                    Register
                    <ArrowRight className="ml-2 -mr-1 h-5 w-5" />
                  </>
                )}
              </button>
            </div>
          </form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">
                  Already have an account?
                </span>
              </div>
            </div>

            <div className="mt-6">
              <a
                href="/login"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-blue-600 bg-white hover:bg-gray-50"
              >
                Log in
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;