import React from 'react';
import { User, Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Encounter } from '../hooks/apis';

interface PatientCardProps {
  encounter: Encounter;
}

const PatientCard: React.FC<PatientCardProps> = ({ encounter }) => {
  const navigate = useNavigate();

  return (
    <a
      key={encounter.id}
      href={`/encounter/${encounter.id}`}
      className="bg-gray-50 dark:bg-tesla-dark-bg rounded-lg p-4 shadow cursor-pointer hover:bg-gray-100 dark:hover:bg-tesla-dark-border/20 transition-colors duration-200"
      onClick={(e) => {
        if (!e.ctrlKey && !e.metaKey) {
          e.preventDefault();
          navigate(`/encounter/${encounter.id}`);
        }
      }}
    >
      <div className="flex items-center space-x-2 mb-2">
        <User className="text-gray-400 dark:text-primary" size={20} />
        <h3 className="font-medium text-gray-900 dark:text-primary">
          {(() => {
            const patientName = encounter.name || 'Unnamed Patient';
            const [familyName, givenName] = patientName.split(' ').reverse();
            return familyName ? `${familyName.toUpperCase()}${givenName ? ', ' + givenName : ''}` : patientName;
          })()}
        </h3>
      </div>
      <div className="text-sm text-gray-600 dark:text-tesla-dark-text/80 mb-1 font-medium">
        {encounter.data?.patient?.chiefComplaintTwoWord && `Chief Complaint: ${encounter.data?.patient?.chiefComplaintTwoWord}`}
      </div>
      <p className="text-sm text-gray-500 dark:text-tesla-dark-text/70 mb-2 line-clamp-4">
        {encounter.description || 'No description available'}
      </p>
      <div className="flex items-center text-xs text-gray-400 dark:text-tesla-dark-text/50">
        <Clock className="mr-1" size={12} />
        {new Date(encounter.updatedAt).toLocaleDateString()}
      </div>
    </a>
  );
};

export default PatientCard; 