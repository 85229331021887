import React from 'react';

const DebugPage: React.FC = () => {
  // Filter for only public env variables (those starting with REACT_APP_)
  const publicEnvVars = Object.entries(process.env).filter(([key]) =>
    key.startsWith('REACT_APP_')
  );

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Debug Information</h1>
      <div className="bg-gray-100 p-4 rounded-lg">
        <h2 className="text-xl font-semibold mb-2">Public Environment Variables</h2>
        <pre className="whitespace-pre-wrap">
          {publicEnvVars.map(([key, value]) => (
            <div key={key} className="mb-1">
              <span className="font-mono">
                {key}: {JSON.stringify(value, null, 2)}
              </span>
            </div>
          ))}
        </pre>
      </div>
    </div>
  );
};

export default DebugPage;
