import React from 'react';
import { Loader } from 'lucide-react';

interface SectionHeaderProps {
  title: string;
  isLoading?: boolean;
  expandAll?: boolean;
  onToggleExpandAll: () => void;
  size?: 'sm' | 'md' | 'lg';
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  isLoading,
  expandAll,
  onToggleExpandAll,
  size = 'md'
}) => {
  const titleClasses = {
    sm: 'text-lg font-semibold text-tesla-black dark:text-tesla-dark-text',
    md: 'text-xl font-semibold text-tesla-black dark:text-tesla-dark-text',
    lg: 'text-2xl font-semibold text-tesla-black dark:text-tesla-dark-text'
  }[size];

  const buttonClasses = `
    text-sm px-3 py-1.5 
    text-tesla-gray dark:text-tesla-dark-text/70 
    hover:text-tesla-black dark:hover:text-tesla-dark-text 
    hover:bg-tesla-hover/30 dark:hover:bg-tesla-dark-border/20
    rounded-md
    transition-all duration-200
    focus:outline-none focus:ring-2 
    focus:ring-tesla-gray/20 dark:focus:ring-tesla-dark-text/20
  `;

  return (
    <div className="sticky top-0 bg-tesla-white dark:bg-tesla-dark-surface z-10 p-6 pb-4 border-b border-tesla-lightGray dark:border-tesla-dark-border">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <h2 className={titleClasses}>{title}</h2>
          {isLoading && <Loader className="animate-spin text-tesla-black dark:text-tesla-dark-text ml-3" size={24} />}
        </div>
        <button
          onClick={onToggleExpandAll}
          className={buttonClasses}
        >
          {expandAll ? 'Collapse All' : 'Expand All'}
        </button>
      </div>
    </div>
  );
};

export default SectionHeader; 