const patientSymptoms = [

  `Dr. Suellen S. Li (Medicine): A 47-year-old man was admitted to this hospital because of confusion and acute kidney failure.
The patient had been well until 6 days before the current admission, when fatigue and myalgias developed. During the subsequent 2 days, he continued to report to his job at a restaurant. Four days before this admission, the patient’s coworkers noticed that he was mildly confused. On the day of this admission, the confusion markedly increased, and new word-finding difficulties and garbled speech developed. Emergency medical services were called, and the patient was brought to the emergency department of this hospital.
On evaluation in the emergency department, the patient was not able to participate fully in the interview or a review of systems because of confusion, but he described feeling hot and short of breath. He had not mentioned any recent cough, nausea, vomiting, diarrhea, or headache to his family or coworkers. He had no known recent falls, trauma, travel, or sick contacts.
Additional history was obtained from the patient’s family. He had no known medical problems or drug allergies. He used no prescription medications but took ginseng extract and burdock root supplements. He lived in an urban area of New England with a friend and no pets in a former factory that had been converted into apartments. The patient smoked marijuana occasionally and had smoked cigarettes as a teenager. He drank alcohol infrequently and did not use illicit drugs. His sister had Sjögren’s syndrome, nemaline myopathy, cardiomyopathy, Fanconi’s anemia, and renal tubular acidosis. His parents were healthy.
On examination, the temporal temperature was 35.8°C, the blood pressure 142/78 mm Hg, the pulse 114 beats per minute, the respiratory rate 30 breaths per minute, and the oxygen saturation 96% while the patient was breathing ambient air. The patient appeared anxious. He was alert and oriented; however, when he was asked a question, he often needed clarification and was slow to answer. He could not recite the days of the week backward and had difficulty following complex commands. His face was symmetric, and no dysarthria was noted. Cranial-nerve function, muscle tone and strength, sensation, proprioception, deep-tendon reflexes, and gait were normal. He did not have photophobia. The mucous membranes were moist, and the neck was supple. There was no jugular venous distention. The heart sounds were normal, and the lungs were clear on auscultation. The abdomen was nontender, and there was no hepatosplenomegaly. He had no leg swelling or rash.
The patient had a white-cell count of 13,270 per microliter (reference range, 4500 to 11,000). The blood urea nitrogen level was 117 mg per deciliter (41.8 mmol per liter; reference range, 8 to 25 mg per deciliter [2.9 to 8.9 mmol per liter]), the creatinine level 13.0 mg per deciliter (1149 μmol per liter; reference range, 0.6 to 1.5 mg per deciliter [53 to 133 μmol per liter]), and the creatine kinase level 28,581 U per liter (reference range, 60 to 400). He had hyponatremia, hyperkalemia, and acidosis, as well as elevated blood levels of aspartate aminotransferase and alanine aminotransferase. Laboratory test results are shown in Table 1. A comprehensive urine toxicology screen was negative, as was a serum toxicology screen for acetaminophen, salicylates, ethanol, and tricyclic antidepressants. Nucleic acid testing of a specimen obtained from the nasopharynx was negative for severe acute respiratory syndrome coronavirus 2, adenovirus, human metapneumovirus, influenza virus types A and B, parainfluenza virus types 1 through 4, and respiratory syncytial virus, as well as Bordetella pertussis, B. parapertussis, Chlamydia pneumoniae, and Mycoplasma pneumoniae.
Table 1
<table1>
White-cell count (per μl)
  - 13,270

Differential count (per μl)
  - Neutrophils:
      - 12,560
  - Lymphocytes:
      - 240
  - Monocytes:
      - 230
  - Eosinophils:
      - 10
  - Basophils:
      - 60
  - Immature granulocytes:
      - 170

Hematocrit (%)
  - 46.7

Hemoglobin (g/dl)
  - 16.5

Platelet count (per μl)
  - 191,000

Sodium (mmol/liter)
  - 125

Potassium (mmol/liter)
  - 6.0

Chloride (mmol/liter)
  - 75

Carbon dioxide (mmol/liter)
  - 9

Urea nitrogen (mg/dl)
  - 117

Creatinine (mg/dl)
  - 13.0

Glucose (mg/dl)
  - 184

Anion gap (mmol/liter)
  - 41

Magnesium (mg/dl)
  - 3.7

Calcium (mg/dl)
  - 8.0

Albumin (g/dl)
  - 3.7

Aspartate aminotransferase (U/liter)
  - 418 (9–32)

Alanine aminotransferase (U/liter)
  - 1272 (ref 7-33)

Alkaline phosphatase (U/liter)
  - 74

Total bilirubin (mg/dl)
  - 0.7

Lactic acid (mmol/liter)
  - 2.3

Creatine kinase (U/liter)
  - 28,581

Lactate dehydrogenase (U/liter)
  - 1334

C-reactive protein (mg/liter)
  - 220.4

Erythrocyte sedimentation rate (mm/hr)
  - 69

Ferritin (μg/liter)
  - 5064

d-Dimer (ng/ml)
  - >10,000

Prothrombin time (sec)
  - 16.2

Prothrombin-time international normalized ratio
  - 1.3

Venous blood gases
  - pH:
      - 7.26
  - Partial pressure of carbon dioxide (mm Hg):
      - 27
  - Partial pressure of oxygen (mm Hg):
      - 29

Urine analysis
  - Bilirubin:
      - Negative
  - Urobilinogen:
      - Negative
  - Blood:
      - 3+
  - Glucose:
      - 1+
  - Ketones:
      - Negative
  - Leukocyte esterase:
      - Negative
  - Nitrite:
      - Negative
  - pH:
      - 5.5
  - Specific gravity:
      - 1.014
  - Protein:
      - 2+
  - Red cells (per high-power field):
      - 10–20
  - White cells (per high-power field):
      - 10–20


</table1>
Laboratory Data.
Dr. Melissa C. Price: Imaging studies were obtained. Radiography of the chest revealed an opacity in the right lower lobe (Figure 1A). Computed tomography (CT) of the head was normal.
Figure 1

Imaging Studies of the Chest.
Dr. Li: Intravenous dextrose, insulin, calcium gluconate, and fluids were administered, and treatment with intravenous ceftriaxone and azithromycin was started. A Foley catheter was placed, and oliguria was noted. The patient was admitted to this hospital.
Three hours after the patient’s arrival, the temporal temperature increased to 38.2°C. The respiratory rate increased to 45 breaths per minute, and the oxygen saturation decreased to 89% while the patient was breathing ambient air. Supplemental oxygen was delivered through a nasal cannula at a rate of 6 liters per minute, and the oxygen saturation increased to 98%.
Dr. Price: CT of the chest, abdomen, and pelvis, performed without the administration of intravenous contrast material, showed consolidation in the right lower lobe with minimal adjacent ground-glass opacity (Figure 1B). There was no evidence of lymphadenopathy.
`,
`
Dr. Lucas X. Marinacci: A 72-year-old woman was evaluated for dysarthria and respiratory failure during an admission to this hospital, which had occurred before the coronavirus disease 2019 pandemic.
Two years before the current evaluation, the patient presented to her primary care physician at this hospital with subacute exertional dyspnea, which had been present for more than a year but had progressively worsened during the past several months. The oxygen saturation was 91 to 94% while she was walking. She had edema in both legs. The white-cell count, platelet count, and blood levels of electrolytes, aspartate aminotransferase, alanine aminotransferase, bilirubin, albumin, and hemoglobin were normal, as were test results for kidney function.
Dr. Sandra P. Rincon: A chest radiograph showed an enlarged cardiac silhouette and bilateral interstitial opacities.

Dr. Marinacci: Hydrochlorothiazide was prescribed. At a follow-up evaluation 2 weeks later, the patient’s weight had decreased by 5 kg, but the exertional dyspnea and bilateral leg edema were unchanged. The N-terminal pro–B-type natriuretic peptide level was normal, and the d-dimer level was 3259 ng per milliliter (reference value, <500).
Dr. Rincon: Computed tomographic angiography (CTA) of the chest, performed after the administration of intravenous contrast material, showed no pulmonary emboli. A dilated main pulmonary artery, mosaic attenuation of the lungs, and coronary-artery calcifications were present.

Dr. Marinacci: Four weeks later, the patient presented to her primary care physician with increased exertional dyspnea. Her weight had decreased by 2 kg. The peak expiratory flow was 220 liters per second (expected value according to age, sex, and height, 350). A tapering course of prednisone and inhaled albuterol were prescribed, and her condition improved initially.
The patient was lost to follow-up; her next visit took place 10 months before the current evaluation. At that time, the patient reported recurrent exertional dyspnea, which occurred when she moved from room to room. She reported an episode of transient facial asymmetry with saliva dripping from the left side of her mouth that had developed while she was washing herself; later that day, her sister had noticed that she was slurring words during a telephone call. The white-cell count, platelet count, and blood levels of thyrotropin, glycated hemoglobin, aspartate aminotransferase, alanine aminotransferase, bilirubin, albumin, and hemoglobin were normal; other laboratory test results are shown in Table 1. Furosemide was prescribed.
Table 1

Variable	10 Mo before Admission, Outpatient Clinic  
Sodium (mmol/liter)	143  
Potassium (mmol/liter)	3.8  
Chloride (mmol/liter)	99  
Carbon dioxide (mmol/liter)	30  
Urea nitrogen (mg/dl)	29  
Creatinine (mg/dl)	0.89  
Glucose (mg/dl)	171  
N-terminal pro–B-type natriuretic peptide (pg/ml)	1908  

Two months later, the patient presented to her primary care physician with throat pain and dyspnea that had been present for 3 weeks. An examination was notable for diffuse crackles in both lung fields.
Dr. Rincon: A chest radiograph showed pulmonary edema and a small right pleural effusion.
Dr. Marinacci: Intravenous furosemide was administered. Transthoracic echocardiography showed normal biventricular wall thickness, size, and function. Mild biatrial enlargement, a patent foramen ovale with mild left-to-right shunting, moderate tricuspid regurgitation, and a right ventricular systolic pressure of 60 mm Hg were noted.
On the day before the current evaluation, the patient called her primary care physician to report sore throat with difficulty swallowing that had started 2 days earlier. Because she had slurred speech over the phone, she was referred to the emergency department of this hospital.
In the emergency department, the patient reported dysphagia with food, liquids, and medications. Her daughter reported that her voice had sounded “garbled” and “soft” during the preceding days and also during an episode of transient dysarthria that had developed a few months earlier. Dyspnea and mild leg edema had been present for at least 3 years, along with some orthopnea and an intermittent nonproductive cough. The patient had started using a walker in the past year owing to exertional fatigue, arthritic pain, and weakness in both legs. Two falls had occurred in the previous 8 months. In addition, the patient reported increased generalized weakness and limb weakness, daytime somnolence, and fatigue. She had no diplopia, numbness, dizziness, headache, chest pain, palpitations, bleeding, nausea, abdominal pain, constipation, diarrhea, or urinary symptoms.
The patient’s medical history was notable for severe obesity, coronary-artery calcifications, hypertension, hyperlipidemia, atrial flutter, and hyperparathyroidism. Medications included furosemide, apixaban, aspirin, amlodipine, lisinopril, metoprolol, lovastatin, metformin, and sertraline. She had had no known adverse drug reactions. The patient was retired and divorced, and she lived alone in an assisted-living community. She had smoked two packs of cigarettes daily for 15 years but had quit smoking 20 years earlier, and she did not drink alcohol or use other substances. Her family history was notable for ischemic heart disease. Her father had had myocardial infarctions; a paternal cousin had systemic lupus erythematosus.
On examination, the temporal temperature was 36.2°C, the heart rate 76 beats per minute, the blood pressure 154/88 mm Hg, the respiratory rate 21 breaths per minute, and the oxygen saturation 84% while the patient was breathing ambient air. The weight was 124 kg, and the body-mass index (the weight in kilograms divided by the square of the height in meters) was 53.1. The patient was appropriately oriented, and her speech was fluent. She appeared to have increased work of breathing. The oropharynx was clear; no stridor was noted. Auscultation revealed an irregular heart rhythm with a systolic murmur at the base of the left sternal border and crackles in both lungs. The patient had pitting edema in both legs with venous stasis dermatitis and had tenderness of the knees with mild crepitus but no erythema or effusion.
The white-cell count, platelet count, and blood levels of calcium, magnesium, phosphate, creatine kinase, lactate, aspartate aminotransferase, alanine aminotransferase, bilirubin, albumin, globulin, lipase, and hemoglobin were normal; other laboratory test results are shown in Table 2. Urinalysis showed 1+ protein. Testing of an oropharyngeal swab for group A streptococcal antigen was negative, and a throat culture showed no growth.
Dr. Rincon: A chest radiograph showed an enlarged cardiac silhouette, low lung volumes, and bilateral interstitial opacities  

Table 2  
Variable	On Presentation, Emergency Department  
Sodium (mmol/liter)	139  
Potassium (mmol/liter)	4.7  
Chloride (mmol/liter)	98  
Carbon dioxide (mmol/liter)	22  
Urea nitrogen (mg/dl)	16  
Creatinine (mg/dl)	0.86  
Glucose (mg/dl)	120  

Dr. Marinacci: Electrocardiography was notable for atrial flutter with a ventricular rate of 75 beats per minute, a rightward axis, and low precordial QRS voltage. Continuous positive airway pressure was administered with a cardiology and neurology consult.
Dr. Rincon: CT scans showed chronic lacunar infarcts and microangiopathy. MRI scans revealed no acute infarction.  
`

];

export const getRandomSymptom = (): string => {
  const randomIndex = Math.floor(Math.random() * patientSymptoms.length);
  return patientSymptoms[randomIndex];
};

export default patientSymptoms;